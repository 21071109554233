/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/pooleditdialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import { Button, Input, Modal, Select, Tooltip } from "antd";

import LoadManagementModeModal from "./../modals/LoadManagementModeModal";
import DynamicLoadManagementDropdownModal from "./../modals/DynamicLoadManagementDropdownModal";
import NewCategoryDialog from "../category/NewCategoryDialog";

/* --------------------
      POOLEDITDIALOG
  ------------------- */
export default function PoolEditDialog(props) {
  //* -- VARIABLES -- *//
  const { Option } = Select;

  //* -- STATES -- *//
  const [pool_name, setPool_name] = useState("");
  const [load_management_mode, setLoad_management_mode] = useState();
  const [user_id, setUser_id] = useState("");
  const [max_amp, setMax_amp] = useState();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [category, setCategory] = useState("");

  // * -- DYNAMIC VALUE STATES * -- //
  const [ipAddress, setIpAddress] = useState("0.0.0.0"); 
  const [ipAddress0, setIpAddress0]= useState("0");
  const [ipAddress1, setIpAddress1]= useState("0");
  const [ipAddress2, setIpAddress2]= useState("0");
  const [ipAddress3, setIpAddress3]= useState("0");

  const [meterPosition, setMeterPosition] = useState("");
  const [meterType, setMeterType] = useState(""); 
  
  const [loadManagementModeModalOpen, setLoadManagementModeModalOpen] = useState(false);
  const [dynamicLoadManagementModalOpen, setDynamicLoadManagementModalOpen] = useState(false);
  const [categoryDialogIsOpen, setCategoryDialogIsOpen] = useState(false);
  //* -- COMPONENT UPDATE -- *//
  // When selected pool in parent table changes, the provided prop and thus these components states will be updated
  useEffect(() => {
    if (props.selectedPool !== undefined) {
      console.log(props.selectedPool); 
      setPool_name(props.selectedPool.pool_name);
      setUser_id(props.selectedPool.user_id);
      setLoad_management_mode(props.selectedPool.load_management_mode);
      setMax_amp(props.selectedPool.max_amp);
      setCategory(props.category);
      if (props.selectedPool.dynamic_lm_information !== undefined) {
        setIpAddress(props.selectedPool.dynamic_lm_information.ip_address);
        const ipAddresses = props.selectedPool.dynamic_lm_information.ip_address.split('.');
        console.log(ipAddresses);
        setIpAddress0(ipAddresses[0]);
        setIpAddress1(ipAddresses[1]);
        setIpAddress2(ipAddresses[2]);
        setIpAddress3(ipAddresses[3]);
        setMeterPosition(props.selectedPool.dynamic_lm_information.meter_position)
        setMeterType(props.selectedPool.dynamic_lm_information.meter_type);
      }
    }
  }, [props.selectedPool]);

  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel = () => {
    props.setEditIsOpen(false);
  };
  const handleOpenLoadManagementModeModal = () => {
    setLoadManagementModeModalOpen(true);
  };
  const handleOpenNewCategoryModal = () => {
    setCategoryDialogIsOpen(true);
  }

  const handleSetLoadManagementMode = (e) => {
    console.log(e); 
    if (e === "DYNAMIC" || e === "DYNAMIC_WITH_BACKCOUPLING"){
      setDynamicLoadManagementModalOpen(true)
    }
    setLoad_management_mode(e);
  }

  const handleChangeMeterType = (e) => {

    setMeterType(e);
  }
  const handleChangeMeterPosition = (e) => {
    setMeterPosition(e);
  }
  
  const handleChangeIpAddress0 = (e) => {
    console.log(e);
    setIpAddress0(filterIpAddressValue(e));
    const newAddress = `${filterIpAddressValue(e)}.${ipAddress1}.${ipAddress2}.${ipAddress3}`;
    setIpAddress(newAddress);
  }
  
  const handleChangeIpAddress1 = (e) => {
    setIpAddress1(filterIpAddressValue(e));
    const newAddress = `${ipAddress0}.${filterIpAddressValue(e)}.${ipAddress2}.${ipAddress3}`;
    setIpAddress(newAddress);
  }

  const handleChangeIpAddress2 = (e) => {
    setIpAddress2(filterIpAddressValue(e));
    const newAddress = `${ipAddress0}.${ipAddress1}.${filterIpAddressValue(e)}.${ipAddress3}`;
    setIpAddress(newAddress);
  }

  const handleChangeIpAddress3 = (e) => {
    setIpAddress3(filterIpAddressValue(e));
    setIpAddress(`${ipAddress0}.${ipAddress1}.${ipAddress2}.${filterIpAddressValue(e)}`);
  }

  const filterIpAddressValue = (e) => {
    if ((Number(e) > 255) && (Number(e) !== NaN)) {
      return "255"
    }
    else {
      return Number(e)
    }
  }

  // When "Änderungen speichern" is clicked on edit modal
  const handleSavePool = () => {
    // Check if all inputs are filled correctly
    if (
      pool_name !== "" &&
      user_id !== "" &&
      load_management_mode !== undefined
    ) {
      const lastCategory = props.category; 
      console.log(props.selectedPool);
      setError(false);
      setErrorText("");
      const newPoolBody =       {
        "pool": {
          "category": category,
          /*
          "group": {
            "name": pool_name
          },*/
          "id": props.selectedPoolId,
          "max_power": max_amp,
          "mode": load_management_mode
        }
      }

      const dynamicLmInformation = {
        ip_address: ipAddress,
        meter_position: meterPosition,
        meter_type: meterType
      }
      if (load_management_mode === "DYNAMIC" || load_management_mode === "DYNAMIC_WITH_BACKCOUPLING") {
        newPoolBody.dynamic_lm_information = dynamicLmInformation; 
      }
      console.log(newPoolBody); 
      restservices.updatePool(newPoolBody, props.selectedPoolId);

      let load_management_mode_text = "";
      if (load_management_mode === "NONE" ||  load_management_mode === "kein Lastmanagement" ) {
        load_management_mode_text = "kein Lastmanagement";
      } else if (load_management_mode === "BASIC" || load_management_mode === "Basic" || load_management_mode === "Grundlegend" ) {
        load_management_mode_text = "Basic";
      } else if (load_management_mode === "DOWNSCALING" || load_management_mode === "Downscaling" ) {
        load_management_mode_text = "Downscaling";
      } else if (load_management_mode === "DOWNSCALING_WITH_BACKCOUPLING" ||load_management_mode === "Downscaling m. Rückk."  ) {
        load_management_mode_text = "Downscaling m. Rückk.";
      } else if (load_management_mode === "DYNAMIC" || load_management_mode === "Dynamisch") {
        load_management_mode_text = "Dynamisch";
      } else if (load_management_mode === "DYNAMIC_WITH_BACKCOUPLING" ||load_management_mode === "Dynamisch m. Rückk.") {
        load_management_mode_text = "Dynamisch m. Rückk.";
      } else {
        load_management_mode_text = "Fehler #PT_001";
      }
      let poolList = [... props.poolList];

      const finalPoolList = poolList.map((e, index) => {
        if (e.key === props.selectedPoolKey[0]){
          if(lastCategory !== category) {
            props.setPoolList(data=> data.filter(item=> item.key === index))
            // TODO: Add information that the pool is moved to other category
          }
          else{
            return {
              ...e,
              pool_name: pool_name,
              max_amp: max_amp,
              load_management_mode: load_management_mode,
              load_management_mode_text: load_management_mode_text, 
              dynamic_lm_information: dynamicLmInformation,
            }
          }
        }
        else {
          return {
            ...e,
          }
        }
      })
      if(lastCategory !== category) {
        console.log(props.selectedPoolId); 
        props.setPoolList(data=> data.filter(item=> item.key !== props.selectedPoolId))
        // TODO: Add information that the pool is moved to other category
      }else{
        
        props.setPoolList(finalPoolList);
      }
      
        
      props.setEditIsOpen(false);
    } else {
      // Check if "Poolname" input is filled correctly
      if (pool_name === "" || pool_name === undefined) {
        setError(true);
        setErrorText("Bitte gültigen Poolnamen angeben");
      }

      // Check if "Max. Anschlussleistung" input is filled correctly
      else if (max_amp === "" || max_amp === undefined) {
        setError(true);
        setErrorText("Bitte gültige max. Anschlussleistung angeben");
      }      
      else if (category === "" || category === undefined) {
        setError(true);
        setErrorText("Bitte gültige Kategorie angeben");
      }
      // Check if "Betriebsmodus" inputss is filled correctly
      else if (load_management_mode === "" || load_management_mode === undefined) {
        setError(true);
        setErrorText("Bitte einen Betriebsmodus angeben");
      } else console.log("Fehler #PAD_001");
    }
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={pool_name + " bearbeiten "  }
      open={props.editIsOpen}
      onCancel={handleCancel}
      width="800px"
      closable={false}
      ƒ
      footer={[
        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,

        <Button
          key="submit"
          shape="round"
          type="primary"
          onClick={handleSavePool}
        >
          Änderungen speichern
        </Button>,
      ]}
    >

          {/* Edit Pool Modal */}
    <LoadManagementModeModal
      isOpen={loadManagementModeModalOpen}
      setIsOpen ={setLoadManagementModeModalOpen}
    />
    <DynamicLoadManagementDropdownModal
      isOpen={dynamicLoadManagementModalOpen}
      setIsOpen ={setDynamicLoadManagementModalOpen}
    />
    <NewCategoryDialog
      isOpen={categoryDialogIsOpen}
      setIsOpen ={setCategoryDialogIsOpen}
      category= {category}
      setCategory={setCategory}
      setCategoryList={props.setCategoryList}
    />
      <div className="pooledit-container">
        {/* "Poolname" Input */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">Poolname</p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Input
            disabled
            value={pool_name}
            onChange={(e) => setPool_name(e.target.value)}
            placeholder="Poolnamen eingeben"
          />
        </div>
        {/* "max. Anschlussleistung" Input */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">
              Max. Anschlussleistung
            </p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Input
            value={max_amp}
            onChange={(e) => setMax_amp(e.target.value)}
            suffix="Kilowatt"
            placeholder="Max. Anschlussleistung eingeben"
          />
        </div>
        {/* "Betriebsmodus" Input */}
        <div className="pooladddialog-item-left">
          <p className="pooladddialog-input-description">Betriebsmodus</p>
        </div>
        <div className="pooladddialog-item-right">
          <Select
            value={load_management_mode}
            onChange={(e) => handleSetLoadManagementMode(e)}
            placeholder="Betriebsmodus auswählen"
            style={{ width: "100%" }}
          >
            <Option value="NONE" className="pooladddialog-select-option">
              kein Lastmanagement
            </Option>
            <Option value="BASIC" className="pooladddialog-select-option">
              Basic
            </Option>
            <Option value="DOWNSCALING" className="pooladddialog-select-option">
              Downscaling
            </Option>
            <Option value="DOWNSCALING_WITH_BACKCOUPLING" className="pooladddialog-select-option">
              Downscaling m. Rückk.
            </Option>
            <Option value="DYNAMIC" className="pooladddialog-select-option" onClick={handleOpenLoadManagementModeModal}>
              Dynamisch
            </Option>            
            <Option value="DYNAMIC_WITH_BACKCOUPLING" className="pooladddialog-select-option" onClick={handleOpenLoadManagementModeModal}>
              Dynamisch m. Rückk.
            </Option>
          </Select>
          <Tooltip title={"Was bedeutet das?"}>
            <Button 
              shape="round"
              onClick={handleOpenLoadManagementModeModal}>
                ?
            </Button>
          </Tooltip>

        </div>
        {/* "Kategorie" Input */}
        <div className="pooladddialog-item-left">
          <p className="pooladddialog-input-description">Kategorie</p>
        </div>
        <div className="pooladddialog-item-right">
        <Select
          showSearch
          value={category}
          onChange={(e) => setCategory(e)}
          placeholder="Kategorie eingeben"
          filterOption={(input, option) =>
            (option.props.children).toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}           
        >
          {      
            props.categoryList.map((item, index) => (
              <Option value={item} key={index}>{item}</Option>
            ))
          }     
        </Select>
        
        <Tooltip title={"Zu neuer Kategorie hinzufügen"}>
            <Button shape="round" onClick={handleOpenNewCategoryModal}>
              +
            </Button>
          </Tooltip>
        </div>
   
        {/* Input für dynamisce Zählerwerte */}
        {(load_management_mode===  "DYNAMIC" || 
        load_management_mode===  "DYNAMIC_WITH_BACKCOUPLING" || 
        load_management_mode===  "Dynamisch" ||
        load_management_mode===   "Dynamisch m. Rückk." )? (
          <>
            <div className="pooladddialog-item-left">
              <p className="pooladddialog-input-description">IP-Adresse für ext. Stromzähler</p>
            </div>
            <div className="pooladddialog-item-right">
              <Input.Group compact style={{ width: "100%" }}>
                <Input value={ipAddress0} style={{ width: "22%" }} onChange= {(e) => handleChangeIpAddress0(e.target.value)}  maxLength={3}
                />
                {" . "} 
                <Input value={ipAddress1} style={{ width: "22%" }} onChange= {(e) => handleChangeIpAddress1(e.target.value)}  maxLength={3}
                />
                {" . "} 
                <Input value={ipAddress2} style={{ width: "22%" }} onChange= {(e) => handleChangeIpAddress2(e.target.value)}  maxLength={3}
                />
                {" . "} 
                <Input value={ipAddress3} style={{ width: "22%" }} onChange= {(e) => handleChangeIpAddress3(e.target.value)}  maxLength={3}
                />
        
              </Input.Group>    
            </div>
            <div className="pooladddialog-item-left">
              <p className="pooladddialog-input-description">Anschlussart des Zählers</p>
            </div>
            <div className="pooladddialog-item-right">
            <Select
              value={meterType}
              style={{ width: "100%" }}
              onChange={(e)=> handleChangeMeterType(e)}
            >
              <Option value="SIEMENS" className="pooladddialog-select-option">
                Siemens
              </Option>
              <Option value="KE" className="pooladddialog-select-option">
                KE Electric
              </Option>
            </Select>
            </div>
            <div className="pooladddialog-item-left">
              <p className="pooladddialog-input-description">Wo wird der Zählerverbrauch gemessen?</p>
            </div>
            <div className="pooladddialog-item-right">
            <Select
              value={meterPosition}
              style={{ width: "100%" }}
              onChange={(e)=> handleChangeMeterPosition(e)}
            >
              <Option value="BUILDING_ONLY" className="pooladddialog-select-option">
                Nur am Gebäude OHNE Ladeinfrastruktur
              </Option>
              <Option value="BUILDING_AND_CHARGINGSTATION" className="pooladddialog-select-option">
                Gebäude UND Ladeinfrastruktur
              </Option>
            </Select>
            </div>
          </>)
          :
          (<>
          </>
          )
        }
      </div>
      {/* Error text with dynamic render scss class */}
      <p
        className={
          error ? "pooladddialog-error-text" : "pooladddialog-error-text-hidden"
        }
      >
        {errorText}
      </p>
    </Modal>
  );
}
