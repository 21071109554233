/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../../design/components/whatsnewmodal.scss";

import { restservices } from "../../../rest-services.js";
// Ant design import
import { Button,  Modal, Spin } from "antd";

/* --------------------
      WhatsNewModal
  ------------------- */
export default function EdgeDeviceDeleteConfirmModal(props) {
  //* -- STATES -- *//
  const [isLoading, setIsLoading] = useState(false);

  //* -- HANDLE METHODS -- *//
  const handleConfirm = async () => {
    setIsLoading(true);      
    const oldPool = await restservices.getPoolById(props.selectedPoolId);
    //console.log(oldPool); 
    const oldGroup = await restservices.getGroup(props.selectedPoolId);
    //console.log(oldGroup); 
    // finally insert the new edge device to the array 
    //console.log(props.selectedEdgeDevice); 
    var newEdgeList = oldPool.data.cp_config;
    var newStationList = oldGroup.data.stations;

    newEdgeList.forEach((edge, i )=> {
     
      if (edge.identity === props.selectedEdgeDevice.id){
        
        newEdgeList.splice(i, 1)
      }
    })

    newStationList.forEach((edge, i )=> {
     
      if (edge.identity === props.selectedEdgeDevice.id){
        
        newStationList.splice(i, 1)
      }
    })
     
    const newBody = 
    {
      pool: {
        "cp_config":newEdgeList
      }
    };
    const newGroup = {
      "group": {
        "stations": newStationList,
      }
    }

    let groupResult = await restservices.updateGroup(props.selectedPoolId, newGroup);

    //console.log(newBody);
    await restservices.updatePool(newBody, props.selectedPoolId).then(data => {
    })
    let tempEdgeDeviceList = [...props.edgeDeviceList];
    //Simply reload the pseool list
    tempEdgeDeviceList.forEach((edge,i) =>{
      if (props.selectedEdgeDeviceId == edge.id){
        tempEdgeDeviceList.splice(i, 1);
      }
    })
    setIsLoading(false);
    props.setEdgeDeviceList(tempEdgeDeviceList);
    props.setIsOpen(false);
    props.setEdgeDeviceDeleteSuccessModalIsOpen(true);
  }
  // When "Schließen" button is clicked on edit modal, dependent from checkbox
  const handleClose = () => {
    props.setIsOpen(false);
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Löschen des Ladepunkts" 
      }
      className="xxxx"
      visible={props.isOpen}
      closable={false}
      footer={[
        <Button 
          danger
          key="confirm" 
          shape="round" 
          type="primary" 
          onClick={handleConfirm}
        >
          Ja, Ladestation aus Pool löschen
        </Button>,
        <Button 
          key="close" 
          shape="round" 
          onClick={handleClose}
        >
          Nein
        </Button>,
      ]}
    >
      <Spin 
        spinning={isLoading}
        tip="Löschen des Pools"
      >
      <p className="whatsnewmodal-text">
        Sind Sie sicher, dass Sie die Ladestation aus dem Pool entfernen möchten?
      </p>

      </Spin>

    </Modal>
  );
}
