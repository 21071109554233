// ** -- This manages the user dialog -- **//

/* -------------
      IMPORTS  
  -------------- */
// React import
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/userdialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import {Table, Button, Input, Modal, Select, List} from "antd";
import {
  EditFilled,
  PauseCircleFilled,
  PlayCircleFilled,
  PlusCircleFilled,
  MinusOutlined,
  LinkOutlined
} from "@ant-design/icons";
/* --------------------
    
  USERDIALOG
  ------------------- */

//* -- HANDLE METHODS -- *//
// When "Abbrechen" button is clicked on edit modal



export default function NewCategoryDialog(props) {
  
  const [currentCategory, setCurrentCategory] = useState("");
  const [error, setError] = useState("");

  const handleCancel = () => {
    props.setIsOpen(false);
  }

  const handleSaveCategory = async () => {
    if (currentCategory !== ""){
      props.setCategory(currentCategory); 
      props.setIsOpen(false);
      props.setCategoryList((prevState) => 
        [
          ...prevState,
          currentCategory,
        ]
      );
      // TODO: New pool must still be loaded
    }
    else{
      setError("Bitte gültige Kategorie eingegeben");
    }
  }

  useEffect(() => {

  }, []);

    //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={"Pool zur neuen Kategorie hinzufügen"}
      open={props.isOpen}
      onCancel={handleCancel}
      width="800px"
      closable={false}
      footer={[
        <Button key="userDialogCancel" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,
        <Button key="userDialogAddUser" icon={<PlusCircleFilled />} shape="round"  onClick={handleSaveCategory}>
          Kategorie hinzufügen
        </Button>     

      ]}
    >
      
      <div className="pooledit-container">
        {/* Category */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">
              Namen der  neuen Kategorie
            </p>
          </div>
        </div>
        <div className="pooladddialog-item-right">      
          <Input
            value={currentCategory}
            onChange={(e) => setCurrentCategory(e.target.value)}
            placeholder="Kategorienamen eingeben"
          />
        </div>
      </div>
      {/* Error text with dynamic render scss class */}
      <p
        className={
          error ? "pooladddialog-error-text" : "pooladddialog-error-text-hidden"
        }
      >
        {error}
      </p>
    </Modal>
  );
}