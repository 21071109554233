// ** -- This manages the live data dialog -- **//

/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/edgedeviceadddialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import {Table, Button, Input, Modal, Select, Card, Spin } from "antd";

/* --------------------
    
  USERDIALOG
  ------------------- */


let { Socket } = require('phoenix-channels');


export default function LiveDataDialog(props) {

  const [selectedLiveData, setSelectedLiveData] = useState([]);
  const liveDataColumn = [
    {
      title: "LS-Name",
      dataIndex: "friendly_name",
    },
    {
      title: "EVSE ID",
      dataIndex: "evse_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: status => {
        switch(status) {
          case "Available": 
            return ("Verfügbar"); 
          case "Preparing":
            return ("Vorbereiten");
          case "SuspendedEV":
            return ("Fahrzeug im Ruhezustand");
          case "SuspendedEVSE":
            return ("Ladestation im Ruhezustand");
          case "Charging":
            return ("Laden aktiv");
          case "Finishing":
            return ("Beenden");
          case "Faulted":
            return ("Fehlerhaft");
        }
      }
    },
    {
      title: "Spannung (V)",
      dataIndex: "measurements",
      render: e => {
        let renderString = "";
        try {
          ////console.log(e);
          if (e !== undefined && e.outlet.voltage !== undefined && e !== null) {
            e.outlet.voltage.forEach((v, i) => {
              renderString += `${v.toFixed(2)}`;
              if (i <= 1) {
                 renderString += " / ";
              }
            })
          }
          return renderString;
        }
        catch (error){
            //console.log(error);
        }
      }
    },
    {
      title: "Stromstärke (A)",
      dataIndex: "measurements",
      render: e => {
        let renderString = "";
        try {

          if (e !== undefined && e.outlet.current !== undefined && e !== null) {
            e.outlet.current.forEach((v, i) => {
              renderString += `${v.toFixed(2)}`;
              if (i <= 1) {
                renderString += " / ";
             }
            })
          }
          return renderString;
        } catch (error){

          //console.log(error);
          return renderString;
        }
        ////console.log(e);
      }
    },
    {
      title: "Leistung (kW)",
      dataIndex: "measurements",
      render: e => {
        let renderString = "";
        try {
          //console.log(e);
          if (e !== undefined && e.outlet.power !== undefined && e !== null) {
            e.outlet.power.forEach((v, i) => {
              renderString += `${v.toFixed(2)}`;
              if (i <= 1) {
                renderString += " / ";
              }
            })
          }
          return renderString;
        }
        catch (error){

          //console.log(error);
          return renderString;
        }
      }
    },
    {
      title: "Temperatur (°C)",
      dataIndex: "measurements",
      render: e => {
        let renderString = "";
        try {
          if (e !== undefined && e.temperature !== undefined && e !== null) {
            renderString = e.temperature;
          }
          return renderString;
        }
        catch (error){

          //console.log(error);
        }
      }
    },
  ];


  useEffect(()=>{
    //console.log("live data dialog selected for " + props.liveDataPoolName + props.liveData) ;
    //console.log(""); 
    //console.log(props.liveData); 
    //console.log((props.liveDataPoolId.length !==0) ); 
    props.liveData.forEach((e) => {
      if (e.id === props.liveDataPoolId) {
        //console.log(e); 
        setSelectedLiveData(e.chargepoints);
      }
    })
  }, [props.liveDataPoolId]);
  
  useEffect(()=>{
    ////console.log("live data changed" ) ;
    ////console.log(props.liveData); 
    props.liveData.forEach((e) => {
      if (e.id === props.liveDataPoolId) {
        ////console.log(e); 
        setSelectedLiveData(e.chargepoints);
        //console.log(e.chargepoints); 
      }
    })
  }, [props.liveData]);

  const handleCancel =  () => {
    props.setShowLiveDataDialog(false);
  };

  return(
  <Modal
    title={"Live-Daten des Pools " + props.liveDataPoolName }
    open={props.showLiveDataDialog}
    width={1300}
    onCancel={handleCancel}
    
    footer={[
      <Button key="cancel" shape="round" onClick={handleCancel}>
        Schließen
      </Button>
    ]}
  >
    <div className="pooledit-container">
      <div className="connectiqtable-card-content">
        <div>
          Konfigurierte Maximal-Ladeleistung: {props.maxAmp} kW
        </div>
        <br>
        </br>
        <div>
          <Table
            columns={liveDataColumn}
            dataSource={selectedLiveData}
            scroll={{ x: "max-content" }}
            pagination={{ pageSize: 6 }}
            bordered
            size="sm"
            
          />
        </div>
        <div>
          Achtung: Die Live-Daten könnten unter Umständen verzögert ankommen.
        </div>
      </div>
    </div>

  </Modal>
    
  );
}

/* add behind summary
              summary={() => {

                let totalSetCurrent = 0;
                let actualCurrent = [0,0,0];
                let activePower = [0,0,0];
                let actualCurrentText = "";
                let activePowerText = "";
                props.liveData.forEach((rowData)=> {
                  rowData.set_amp=Math.min(rowData.set_amp, rowData.max_amp);
                  totalSetCurrent+=Math.floor(rowData.set_amp * 100) / 100.0;
                  rowData.sampled_value.current.forEach((current, i)=> {
                    actualCurrent[i]+=Math.floor(rowData.sampled_value.current[i] *100) / 100.0;
                  });
                  rowData.sampled_value.power.forEach((power, i)=> {
                    activePower[i]+=Math.floor(rowData.sampled_value.power[i] * 100) / 100.0;
                  });

                });
                actualCurrent.forEach((data,i)=>{
                  actualCurrentText+=  Math.floor(data * 100)/100.0 +" / ";
                });
                activePower.forEach((data,i)=>{
                  activePowerText+= Math.floor(data * 100)/100.0 +" / ";
                });
                return(
                  <Table.Summary.Row>
                    <Table.Summary.Cell key={0} colSpan={4}>Summe</Table.Summary.Cell>
                    <Table.Summary.Cell key={1} >{totalSetCurrent}</Table.Summary.Cell>
                    <Table.Summary.Cell key={2} >{actualCurrentText}</Table.Summary.Cell>
                    <Table.Summary.Cell key={3} >{activePowerText}</Table.Summary.Cell>
                  </Table.Summary.Row>
              )}}*/