/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";

// Style import
import "../../design/components/edgedeviceeditdialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import { Button, Input, Modal, Spin } from "antd";

/* --------------------
      EDGEDEVICEEDITDIALOG
  ------------------- */
export default function EdgeDeviceCurrentEditDialog(props) {
  //* -- VARIABLES -- *//
  //* -- STATES -- *//
  const [max_amp, setMax_amp] = useState();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  //* -- COMPONENT UPDATE -- *//
  // When selected edge device in parent table changes, the provided prop and thus these components states will be updated
  useEffect(() => {
    if (props.selectedEdgeDevice !== undefined) {
      setMax_amp(props.selectedEdgeDevice.max_amp);
    }
  }, [props.selectedEdgeDevice]);

  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel = () => {
    props.setEditEdgeCurrentIsOpen(false);
  };

  // When "Edge-Device anlegen" button is clicked on edit modal
  const handleSaveEdgeDevice = async () => {
    // Check if all inputs are filled correctly
    let edgeSetStatus = "";
    setLoading(true);
    setLoadingText("Validiere Konfiguration...")
    if (
      /^\d+$/.test(max_amp)
    ) 
    {
      setLoadingText("Sende Konfiguration an die Ladestation...")
      setError(false);
      setErrorText("");

      const realClientId = props.selectedEdgeDevice.id; 
      await restservices.setAmp(realClientId, max_amp).then(result => {
        //console.log(realClientId);
        //console.log(result.data.status);
        edgeSetStatus = result.data.status;
      }
    );
      let edgeDeviceList = props.edgeDeviceList;
      ////console.log(props.edgeDeviceList)
      edgeDeviceList.forEach((edgeDevice, i) => {
        ////console.log(edgeDevice.key);
        ////console.log(props.selectedEdgeDeviceKey);
        if (edgeDevice.key === props.selectedEdgeDeviceKey[0]){
          ////console.log("It's a match", edgeDevice.key);
          edgeDeviceList[i].max_amp =max_amp;
          props.setEdgeDeviceList(edgeDeviceList);  
        }
      })
    } else {
      // Check if "Max. Stromstärke" input is filled correctly
      if (!/^\d+$/.test(max_amp) || max_amp === undefined) {
        setError(true);
        setErrorText("Bitte gültige max. Anschlussleistung angeben! Es sind nur Zahlen erlaubt");
        ////console.log("max_amp fehlerhaft");
      } else {console.log("Fehler #EDAD_001");
      }
    }
    if (edgeSetStatus === "ok" || edgeSetStatus === "OK"){
      props.setEditEdgeCurrentIsOpen(false);
      setError(false);
      props.setEdgeDeviceCurrentSetModalIsOpen(true);
    }
    else if (edgeSetStatus === "error"){
      props.setEditEdgeCurrentIsOpen(false);
      setError(false);
      props.setEdgeDeviceCurrentErrorModalIsOpen(true);
    }
    
    setLoading(false);
  };



  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={"Default-Ampere bearbeiten für " + props.selectedEdgeDevice.evse_id}
      visible={props.editEdgeCurrentIsOpen}
      onCancel={handleCancel}
      width="800px"
      footer={[

        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,

        <Button
          key="submit"
          shape="round"
          type="primary"
          onClick={handleSaveEdgeDevice}
        >
          Änderungen speichern
        </Button>,
      ]}
    >
      <Spin 
        spinning={loading}
        tip={loadingText}
        style={{ height: '50%' }}
      >
      <div className="edgedeviceeditdialog-container">

        {/* "max. Anschlussleistung" Input */}
        <div className="edgedeviceeditdialog-item-left">
          <div>
            <p className="edgedeviceeditdialog-input-description">
              Max. Anschlussleistung
            </p>
          </div>
        </div>
        <div className="edgedeviceeditdialog-item-right">
          <Input
            value={max_amp}
            onChange={(e) => setMax_amp(e.target.value)}
            suffix="Ampere"
            placeholder="Max. Anschlussleistung eingeben"
          />
        </div>
      </div>
      {/* Error text with dynamic render scss class */}
      <p
        className={
          error
            ? "edgedeviceeditdialog-error-text"
            : "edgedeviceeditdialog-error-text-hidden"
        }
      >
        {errorText}
      </p>
      </Spin>
    </Modal>
  );
}
