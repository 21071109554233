/* -------------
      IMPORTS  
  -------------- */
// React import
import { useAuth0 } from "@auth0/auth0-react";
// Ant Design import
import { Button, Col, Form, notification, Row, Spin } from "antd";
import React, { useState } from "react";
// Style import
import "../design/pages/login.scss";
// Ressource import
import chargeIQLogo from "../resource/20200923_chargeIQ_Logo_white_small.png";
// Authentification import

/* ------------
      LOGIN  
  ------------ */

export default function Login(props) {
  //* -- STATES -- *//
  const [isLoading, setIsLoading] = useState(false);

  //* -- Notification for login helper -- */
  const openNotification = () => {
    notification["warning"]({
      message: "Informationen zum Login",
      description: (
        <>
          <h4>Wie komme ich an meine Zugangsdaten?</h4>
          <p>
            Nachdem du bei uns Partner geworden bist, erhälst du deine
            Zugangsdaten per Mail zugesendet. Mit diesen Daten kannst du dich
            dann hier in dein Dashboard anmelden. Beachte dabei bitte, dass die
            Zugangsdaten derzeit nicht geändert werden können.
          </p>
          <h4>Was mache ich, wenn der Login nicht klappt?</h4>
          <p>
            Ein manuelles Ändern der Zugangsdaten oder ein Zurücksetzen des
            Passwortes ist derzeit leider noch nicht möglich. Wende dich dafür
            bitte direkt an uns (support@chargeiq.de), dann können wir dir neue
            Zugangsdaten ausstellen. Wir arbeiten bereits daran, dass du
            zukünftig deine Daten auch selbst ändern kannst. Bis dahin bitten
            wir noch um ein wenig Geduld.
          </p>
        </>
      ),
      duration: 0,
      style: { color: "#8a8a8a" },
    });
  };

  //* --  STATES  -- *//
  const { loginWithRedirect } =
    useAuth0();

  //* --  MAIN RETURN  -- *//
  return (
    <div>
      <Row style={{ height: "100vh" }}>
        {/* Left part of login page */}
        <Col xs={24} md={10} className="login-item-left">
          <div style={{ maxWidth: "750px", width: "80%" }}>
            {/* chargeIQ logo */}
            <img
              src={chargeIQLogo}
              className="login-logo"
              alt="chargeIQ GmbH"
            />
            {/* Title */}
            <h1 className="login-headline">Elektriker-Dashboard</h1>
            <p className="login-subheadline">
              Dein nutzerfreundliches Dashboard zur Verwaltung der Ladepunkte
              deiner Kunden!
            </p>
          </div>
          {/* Footer with link to homepage and version description*/}
          <div className="login-item-left-footer">
            <p className="login-text">
              Version {process.env.REACT_APP_SOFTWARE_VERSION} | Copyright ©{" "}
              <a href="https://chargeiq.de" style={{ textDecoration: "nones" }}>
                chargeIQ GmbH
              </a>{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </Col>

        {/* Right part of login page */}
        <Col span={14} className="login-item-right">
          <div style={{ width: "70%", maxWidth: "750px" }}>
            <Spin spinning={isLoading} tip="Es wird eingeloggt...">
              <h2 className="login-form-headline">
                Mit deinem zentralen chargeIQ Nutzer anmelden
              </h2>
              {/* Form for login inputs (username, password) */}
              <Form
                size="large"
                layout="vertical"
                hideRequiredMark
                labelAlign="left"
                // onFinish={handleSubmitLogin}
              >
                {/* Username input */}
                {/* <Form.Item
                  label={<p className="login-form-label">Nutzername</p>}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Bitte Nutzernamen angeben!",
                    },
                  ]}
                  className="login-form-item"
                  style={{ transform: "translateY(15px)" }}
                >
                  <Input
                    value={props.identity}
                    onChange={(e) => props.setIdentity(e.target.value)}
                  />
                </Form.Item> */}

                {/* Password input */}
                {/* <Form.Item
                  label={<p className="login-form-label">Passwort</p>}
                  name="password"
                  rules={[
                    { required: true, message: "Bitte Passwort angeben!" },
                  ]}
                  className="login-form-item"
                >
                  <Input.Password
                    value={props.password}
                    onChange={(e) => props.setPassword(e.target.value)}
                  />
                </Form.Item> */}

                {/* Error text when login fails */}
                {/* <p
                  className={
                    error ? "login-error-text" : "login-error-text-hidden"
                  }
                >
                  {errorText}
                </p> */}
                {/* User interaction section */}
                <Row align="middle" className="login-form-ui-row">
                  {/* <Col span={12}>
                    <p className="login-form-hint" onClick={openNotification}>
                      Probleme bei der Anmeldung?
                    </p>
                  </Col> */}
                  {/* "Anmelden" button */}
                  {/* <Col span={12} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      size="large"
                    >
                      Anmelden
                    </Button>
                  </Col> */}
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={() => loginWithRedirect()}
                    >
                      Zum Login
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
}
