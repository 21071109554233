/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/pooltable.scss";
// Style import
import "../../design/components/edgedevicetable.scss";

// MaterialUI import
import Card from "@material-ui/core/Card";

// Ant Design import
import { Table, Button,  Select } from "antd";
import {
  EditFilled,
  PauseCircleFilled,
  PlayCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";

// Services import
import { restservices } from "../../rest-services.js";

// Auth Service import
// Components import
import PoolEditDialog from "./PoolEditDialog";
import PoolDeleteDialog from "./PoolDeleteDialog";
import PoolAddDialog from "./PoolAddDialog";
import LiveDataDialog from "../../components/livedata/LiveDataDialog";

import EdgeDeviceAddDialog from "../edgeDevice/EdgeDeviceAddDialog";
import EdgeDeviceEditDialog from "../edgeDevice/EdgeDeviceEditDialog";
import EdgeDeviceCurrentEditDialog from "../edgeDevice/EdgeDeviceCurrentEditDialog";
import EdgeDeviceCurrentSetModal from "../modals/edgeDevice/EdgeDeviceCurrentSetModal";

import EdgeDeviceDeleteConfirmModal from "../modals/edgeDevice/EdgeDeviceDeleteConfirmModal";
import EdgeDeviceDeleteSuccessModal from "../modals/edgeDevice/EdgeDeviceDeleteSuccessModal";
import EdgeDeviceRestartModal from "../modals/edgeDevice/EdgeDeviceRestartModal";
/* -------------
    POOLTABLE  
-------------- */


export default function PoolTable(props) {
  
  //* --  STATES  --  *//

  // Live data states
  const [showLiveDataDialog, setShowLiveDataDialog] = useState(false);
  const [liveDataPoolId, setLiveDataPoolId] = useState("");
  const [liveDataPool, setLiveDataPool] = useState({});
  const [liveDataPoolName, setLiveDataPoolName] = useState("");
  //const [liveData, setLiveData] = useState([]);
  const [liveDataMaxAmp, setLiveDataMaxAmp] = useState(0.0);


  // States for dialogs controlling pools
  const [addPoolValue, setAddPoolValue] = useState({});
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [deletePoolIsOpen, setDeletePoolIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);

  // States for dialogs controlling edge device
  // add edge
  const [addEdgeIsOpen, setAddEdgeIsOpen] = useState(false);

  //edit edge description
  const [editEdgeIsOpen, setEditEdgeIsOpen] = useState(false);

  //edit edge current
  const [editEdgeCurrentIsOpen, setEditEdgeCurrentIsOpen] = useState(false);
  const [edgeDeviceCurrentSetModalIsOpen, setEdgeDeviceCurrentSetModalIsOpen] = useState(false);
  const [edgeDeviceCurrentErrorModalIsOpen, setEdgeDeviceCurrentErrorModalIsOpen] = useState(false);

  //edit edge delete
  const [deleteEdgeConfirmIsOpen, setDeleteEdgeConfirmIsOpen] = useState(false);
  const [deleteEdgeSuccessModalIsOpen, setDeleteEdgeSuccessModalIsOpen] = useState(false);

  //restart charging station
  const [restartModalIsOpen, setRestartModalIsOpen] = useState(false);

  //* -- COMPONENT MOUNT -- *//
  useEffect(() => {
    if (liveDataPoolId !== "")
    {
      const loadAllEdgeFunction = () => {
        loadAllEdgeDevices(props.selectedPoolUserId, props.selectedPoolId);
      }
      
      const interval = setInterval(loadAllEdgeFunction, 6000);
      return () => {
        clearInterval(interval);
    };

    }
    
    
    /*restservices.getUserWithCentralUserId(props.electricianId).then((e)=> {
      //console.log(e); 
    });*/
  }, []);

  //* --  FUNCTIONS  -- *//


  const poolRowSelection = {
    onChange: async (selectedRowKeys, selectedRows) => {
      let currentSelectedPool = selectedRows[0]; //Since state chagne is not sync
      //console.log(currentSelectedPool);
      setLiveDataMaxAmp(selectedRows[0].max_amp)
      props.setSelectedPool(currentSelectedPool);
      props.setSelectedPoolKey(selectedRowKeys);
      props.setSelectedPoolId(currentSelectedPool._id);
      props.setSelectedPoolUserId(currentSelectedPool.userId);
      setLiveDataPool(currentSelectedPool);
      setLiveDataPoolId(currentSelectedPool._id);
      setLiveDataPoolName(currentSelectedPool.pool_name);

      loadAllEdgeDevices(currentSelectedPool._id).then((devices) => {
        ////console.log(devices); 
        
      });

      
    }
  };


  
  const edgeRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      props.setSelectedEdgeDevice(selectedRows[0]);
      props.setSelectedEdgeDeviceId(selectedRows[0].id);
      props.setSelectedEdgeDeviceKey(selectedRowKeys);
    },
  };
 
  //* -- TABLE CONFIGURATION -- *//
  const { Option } = Select;

  const loadAllEdgeDevices = async (pool_id) => {

    
    let edgeDeviceConfigs;
    //let cpList = []; 
    let chargepointArray = []; 
    await restservices.getPoolById(pool_id)
      .then(data => {
        //console.log(data);     
        props.setEdgeDeviceList([]);
        props.setCpList([]); 
        edgeDeviceConfigs = data.data.cp_config;
        const chargepoints = data.data.chargepoints; 
        //console.log(chargepoints); 
        
       ////console.log(cpList); 
        //console.log(edgeDeviceConfigs);

        if (chargepoints.length > 0){
          let phaseRotation = [1,2,3];
          chargepoints.forEach((edgeDevice, i) => {

            let max_amp = ""; 
            let phaseRotationText = "";
            // get name  and evse id 
            if (edgeDeviceConfigs.length > 0) {
              edgeDeviceConfigs.forEach((e) => {
                //console.log(e)
                if (e.identity === edgeDevice.identity ){
                  e.phase_rotation.forEach((pow, i) => {
                    phaseRotationText += pow ;
                    if (i <= 1) {
                      phaseRotationText += " / ";
                   }
                  });
                  phaseRotation = e.phase_rotation;
                  max_amp = e.max_amps;
                }
              })
            }

            //console.log(edgeDevice); 
                // get evse
            const nextEdgeDevice = {
              id: edgeDevice.identity,
              friendly_name: edgeDevice.friendly_name,
              evse_id: edgeDevice.evse_id,
              max_amp: max_amp,
              ev_status: edgeDevice.status,
              temp: edgeDevice.temperature,
              phase_rotation: phaseRotation,              
              phase_rotation_text: phaseRotationText,
              key: i + 1,
            };
            props.setEdgeDeviceList((prevState) => [
              ...prevState,
              nextEdgeDevice,
            ]);
            chargepointArray.push(nextEdgeDevice); 
            const nextCpConfig = {

              id: edgeDevice.identity,
              evse_id: edgeDevice.evse_id,
            }

            props.setCpList((prevState) => [
              ...prevState, 
              nextCpConfig,
            ]         
            ); 

          });
        }
      });
    //TODO: Restservice method needs to be adapted to new data structure (user and client)
    return {
      pool_id: pool_id,
      chargepoints: chargepointArray
    }; 
    
  };

  //When "Pool löschen" is clicked ON EDIT PAGE
  const handleDeletePool = async () => {
    setDeletePoolIsOpen(true); 
  };

  //When "Edge-Device löschen" is clicked on edit modal

  const togglePoolStatus = function (status)  {
    let tempStatus = !status; 
    //console.log(tempStatus); 
    const newPool = {
      "pool": {
          "active": tempStatus
      }
    }
    restservices.updatePool(newPool, props.selectedPoolId).then(() => {
      const tempPoolList = [...props.poolList];
      const finalPoolList = tempPoolList.map((pool) => {
        if (props.selectedPoolId === pool._id){
          return {
            ...pool,
            active: tempStatus,
            activeText: (tempStatus === true ? "aktiv" : "inaktiv"),
          }
        }
        else {
          return {
            ...pool,
          }
        }
      }); 
  
      //console.log(finalPoolList);
      
      props.setPoolList(finalPoolList);
      
      finalPoolList.forEach((e, index)=> {
        if (e._id === props.selectedPoolId) {
          props.setSelectedPool(e);
        }
      })
    });

    //Simply reload the pool list
    // Change the status of pool activeness and change the stats in state
  }

  const handleShowLiveData = async() =>{
    setShowLiveDataDialog(true);
    setLiveDataPoolId(props.selectedPoolId);
  }

  const handleResetEvse = async (data)=>{
    /*let realClientId = "";
    await restservices.getClientIdWithEvseId(data.evse_id).then(data => {
      realClientId = data.data;
    })*/
    //console.log(data);
    await restservices.restartEdgeDevice(data.id).then(data => {
      setRestartModalIsOpen(true);
    });

  }

  // Definition of table columns
    const columns = [   
      /* {
      title: "Nr.",
      dataIndex: "key",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.key - b.key,
    },*/
    {
      title: "Standort-Name",
      dataIndex: "pool_name",
    },
    /*
    {
      title: "User-Email",
      dataIndex: "user_email",
    },
    */
    {
      title: "max. Anschluss-leistung (kW)",
      dataIndex: "max_amp",
      width: "150px"
    },
    {
      title: "Betriebsmodus",
      dataIndex: "load_management_mode_text",
    },
    {
      title: "Kategorie",
      dataIndex: "category",
    },
    {
      title: "Lastmanagement-Status",
      dataIndex: "activeText",
      width: "150px"
    },

  ];


  const edgeDeviceColumns = [
    /*
    {
    title: "Nr.",
    dataIndex: "key",
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.key - b.key,
  },*/
    {
      title: "LS-Name",
      dataIndex: "friendly_name",
    }, 
    {
      title: "EVSE ID",
      dataIndex: "evse_id",
    },
    
    {
      title: "Maximale Stromstärke ",
      dataIndex: "max_amp",
      width: "150px",
    },
    {
      title: "Phasenrotation",
      dataIndex: "phase_rotation_text",
      width: "150px",
    },
    {
      title: "",
      dataIndex: "restart",
      width: "150px",
      render: (text, record) => (
        <Button 
          onClick={()=> handleResetEvse(record)}
          shape="round"
        >
          {"Ladestation neu starten"}
        </Button>
       ),
    }
  ];
  
  //* --  MAIN RETURN  -- *//
  return (

    <div>

    <h2 className="dashboard-topic-headline">Pool Konfiguration</h2>
 
    
      <Card id="pooltable-card">
      
        <div className="pooltable-card-content">
          <div className="pooltable-card-table-wrapper">
            {/* Headline */}
            <h3 className="pooltable-card-headline">{"Übersicht aller Pools "}</h3>

            {/* Pool list  Table */}
            <Table
              columns={columns}
              dataSource={props.poolList}
              scroll={{ x: "max-content" }}
              pagination={{ pageSize: 6 }}
              rowSelection={{
                type: "radio",
                ...poolRowSelection,

              }}
            />
          </div>

          {/* UI Buttons */}
          <div style={{ textAlign: "center", padding: "0px 0px 50px 0px" }}>
            <Button
              icon={<PlusCircleFilled />}
              shape="round"
              onClick={() => setAddIsOpen(true)}
              style={{ margin: "10px 10px" }}
            >
              Neuen Pool hinzufügen
            </Button>
            {props.selectedPool === undefined ? (
              <>
                <Button
                  disabled
                  shape="round"
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Pool bearbeiten
                </Button>
                <Button disabled key="delete" shape="round" danger onClick={handleDeletePool}>
                  Pool löschen
                </Button>
                <Button
                  disabled
                  shape="round"
                  icon={<PlayCircleFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Lastmanagement starten
                </Button>
                <Button 
                   disabled
                   shape="round" 
                   onclick={handleShowLiveData}
                 >
                   Live-Daten anzeigen
                 </Button>
              </>
            ) : (
              <>
                <Button
                  shape="round"
                  onClick={() => setEditIsOpen(true)}
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Pool bearbeiten
                </Button>
                <Button key="delete" shape="round" danger onClick={handleDeletePool}>
                  Pool löschen
                </Button>
                <Button
                  shape="round"
                  icon={
                    (props.selectedPool.active ==="1") ? (
                      <PauseCircleFilled />
                    ) : (
                      <PlayCircleFilled />
                    )
                  }
                  onClick={() => togglePoolStatus(props.selectedPool.active)}
                  style={{ margin: "10px 10px" }}
                >
                  {props.selectedPool.active
                    ? "Lastmanagement beenden"
                    : "Lastmanagement starten"}
                </Button>
                <Button 
                  
                  shape="round" 
                  onClick={handleShowLiveData}
                >
                  Live-Daten anzeigen
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>

      {/* Edit Pool Modal */}
      <PoolEditDialog
        selectedPool={props.selectedPool}
        selectedPoolKey={props.selectedPoolKey}
        selectedPoolId={props.selectedPoolId}
        selectedPoolUserId={props.selectedPoolUserId}
        poolList={props.poolList}
        setPoolList={props.setPoolList}
        editIsOpen={editIsOpen}
        setEditIsOpen={setEditIsOpen}
        addPoolValue={addPoolValue}
        electricianId={props.electricianId} 
        categoryList={props.categoryList}
        setCategoryList={props.setCategoryList}
        category={props.category}
      />

      {/* Add Pool Modal */}
      <PoolAddDialog 
        addIsOpen={addIsOpen} 
        setAddIsOpen={setAddIsOpen} 
        electricianId={props.electricianId} 
        poolList={props.poolList}
        userList={props.userList}
        setPoolList={props.setPoolList}
        setEdgeList={props.setEdgeDeviceList}
        setCpList={props.setCpList}
        groupList={props.groupList}
        category={props.category}
        categoryList={props.categoryList}
        setCategoryList={props.setCategoryList}
        setPoolId={props.setSelectedPoolId}
        userId={props.electricianId}
      />      
      
      {/* Delete Pool Modal */}
      <PoolDeleteDialog 
        deletePoolIsOpen={deletePoolIsOpen} 
        setDeletePoolIsOpen={setDeletePoolIsOpen} 
        electricianId={props.electricianId} 
        poolList={props.poolList}
        userList={props.userList}
        setPoolList={props.setPoolList}
        setEdgeDeviceList={props.setEdgeDeviceList}
        groupList={props.groupList}
        category={props.category}
        selectedPoolId={props.selectedPoolId}
        userId={props.electricianId}
      />

      <LiveDataDialog
        showLiveDataDialog={showLiveDataDialog}
        setShowLiveDataDialog={setShowLiveDataDialog}
        electricianId={props.electricianId}
        liveDataUserId={props.selectedPoolUserId}
        liveDataPoolId={liveDataPoolId}
        liveDataPool={liveDataPool}
        liveDataPoolName={liveDataPoolName}
        liveData={props.liveData}
        setEdgeDeviceList={props.setEdgeDeviceList}
        maxAmp={liveDataMaxAmp}
        accessToken={props.accessToken}
      />
            
      <h2 className="dashboard-topic-headline">
        {"Ladestation-Konfiguration"}
      </h2>


      {/* Edge Device Table */}
      <div>
      <Card id="connectiqtable-card">
        <div className="connectiqtable-card-content">
          <div className="connectiqtable-card-table-wrapper">
            {/* Headline */}

            <h3 className="connectiqtable-card-headline">
              Ladestationen 
            </h3>
            {/* Table */}

            <Table
              columns={edgeDeviceColumns}
              dataSource={props.edgeDeviceList}
              scroll={{ x: "max-content" }}
              pagination={{ pageSize: 6 }}
              bordered
              rowSelection={{
                type: "radio",
                ...edgeRowSelection,
              }}
            />
          </div>
          <div style={{ textAlign: "center", padding: "0px 0px 50px 0px" }}>
            {props.selectedPoolKey === -1 ? (
            <Button
              disabled
              shape="round"
              icon={<PlusCircleFilled />}
              onClick={() => setAddEdgeIsOpen(true)}
              style={{ margin: "10px 10px" }}
            >
              Ladestation hinzufügen
            </Button>
            ) : (
              <Button
              
              shape="round"
              icon={<PlusCircleFilled />}
              onClick={() => setAddEdgeIsOpen(true)}
              style={{ margin: "10px 10px" }}
            >
              Ladestation hinzufügen
            </Button>
            )}
            {props.selectedEdgeDevice === undefined ? (
              <>
                <Button
                  disabled
                  shape="round"
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Ladepunkt konfigurieren
                </Button>
                {/*}
                <Button
                  disabled
                  shape="round"
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Ampere bearbeiten
                </Button>
                {*/}
                
                
              </>
            ) : (
              <>
                <Button
                  shape="round"
                  
                  onClick={() => setEditEdgeIsOpen(true)}
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Ladepunkt konfigurieren
                </Button>
                {/*}
                <Button
                  shape="round"
                  onClick={() => setEditEdgeCurrentIsOpen(true)}
                  icon={<EditFilled />}
                  style={{ margin: "10px 10px" }}
                >
                  Ampere bearbeiten
                </Button>
                {*/}
              </>
            )}
          </div>
        </div>
      </Card>
      {/* Edit Edge-Device Modal */} 
      <EdgeDeviceEditDialog
        selectedEdgeDevice={props.selectedEdgeDevice}
        editEdgeIsOpen={editEdgeIsOpen}
        setEditEdgeIsOpen={setEditEdgeIsOpen}
        edgeDeviceList={props.edgeDeviceList}
        setEdgeDeviceList={props.setEdgeDeviceList}
        selectedEdgeDeviceKey={props.selectedEdgeDeviceKey}
        poolId={props.selectedPoolId}
        userId={props.selectedPoolUserId}
      />

      {/* Add Edge-Device Modal */}
      <EdgeDeviceAddDialog 
        addEdgeIsOpen={addEdgeIsOpen} 
        setAddEdgeIsOpen={setAddEdgeIsOpen} 
        edgeDeviceList={props.edgeDeviceList}
        setEdgeDeviceList={props.setEdgeDeviceList}
        selectedEdgeDeviceKey={props.selectedEdgeDeviceKey}
        evseIdSelection={props.evseIdSelection}
        userList={props.userList}
        cpList={props.cpList}
        setCpList={props.setCpList}
        poolId={props.selectedPoolId}
        poolUserId={props.selectedPoolUserId}
        groupId={props.groupId}
      />

      <EdgeDeviceCurrentEditDialog
        selectedEdgeDevice={props.selectedEdgeDevice}
        editEdgeCurrentIsOpen={editEdgeCurrentIsOpen}
        setEditEdgeCurrentIsOpen={setEditEdgeCurrentIsOpen}
        edgeDeviceList={props.edgeDeviceList}
        setEdgeDeviceList={props.setEdgeDeviceList}
        selectedEdgeDeviceKey={props.selectedEdgeDeviceKey}
        userId={props.selectedPoolUserId}
        edgeDeviceCurrentSetModalIsOpen={edgeDeviceCurrentSetModalIsOpen}
        setEdgeDeviceCurrentSetModalIsOpen={setEdgeDeviceCurrentSetModalIsOpen}
        edgeDeviceCurrentErrorModalIsOpen={edgeDeviceCurrentErrorModalIsOpen}
        setEdgeDeviceCurrentErrorModalIsOpen={setEdgeDeviceCurrentErrorModalIsOpen}
        poolId={props.selectedPoolId}
      />

      <EdgeDeviceCurrentSetModal 
        isOpen={edgeDeviceCurrentSetModalIsOpen}
        setIsOpen={setEdgeDeviceCurrentSetModalIsOpen}
      />

      <EdgeDeviceDeleteConfirmModal 
        isOpen={deleteEdgeConfirmIsOpen}
        setIsOpen={setDeleteEdgeConfirmIsOpen}
        selectedPoolUserId = {props.selectedPoolUserId}
        selectedPoolId = {props.selectedPoolId}
        selectedEdgeDevice= {props.selectedEdgeDevice}
        selectedEdgeDeviceId = {props.selectedEdgeDeviceId}
        edgeDeviceList={props.edgeDeviceList}
        setEdgeDeviceList={props.setEdgeDeviceList}
        edgeDeviceDeleteSuccessModalIsOpen={deleteEdgeSuccessModalIsOpen}
        setEdgeDeviceDeleteSuccessModalIsOpen={setDeleteEdgeSuccessModalIsOpen}
      />

      <EdgeDeviceDeleteSuccessModal 
        isOpen={deleteEdgeSuccessModalIsOpen}
        setIsOpen={setDeleteEdgeSuccessModalIsOpen}
      />

      <EdgeDeviceRestartModal 
        isOpen={restartModalIsOpen}
        setIsOpen={setRestartModalIsOpen}
      />
    </div>
    </div>
    
  );
}
