/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../../design/components/whatsnewmodal.scss";

// Ant design import
import { Button,  Modal } from "antd";

/* --------------------
      WhatsNewModal
  ------------------- */
export default function EdgeDeviceCurrentSetModal(props) {
  //* -- VARIABLES -- *//


  //* -- HANDLE METHODS -- *//
  // When "Schließen" button is clicked on edit modal, dependent from checkbox
  const handleClose = () => {
    props.setIsOpen(false);
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Einstellung der Standard-Ladeleistung"
      }
      className="xxxx"
      visible={props.isOpen}
      closable={true}
      footer={[

        <Button key="close" shape="round" type="primary" onClick={handleClose}>
          Schließen
        </Button>,
      ]}
    >
      <p className="whatsnewmodal-text">
        Einstellung des maximalen Stromwerts ist erfolgreich.
      </p>

    </Modal>
  );
}
