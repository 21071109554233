/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/whatsnewmodal.scss";

// Ant design import
import { Button, Checkbox, Modal } from "antd";

/* --------------------
      WhatsNewModal
  ------------------- */
export default function LoadManagementModeModal(props) {
  //* -- VARIABLES -- *//

  const handleClose = () => {
    props.setIsOpen(false);
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Verschiedene Lastmanagement-Modi"
      }
      className="xxxx"
      width="900px"
      visible={props.isOpen}
      footer={[
        <Button key="close" shape="round" type="primary" onClick={handleClose}>
          Schließen
        </Button>,
      ]}
    >
      <p className="whatsnewmodal-text">
        Für verschiedene Anwendungsszenarien eignet es sich, unterschliedliche Lastmanagement-Modi einzustellen. 
        Hier ist eine Übersicht für verschiedene Lastmanagement-Modi: 
      </p>
      <ul>
        <li>
          <p className="whatsnewmodal-text-headline">
            Kein Lastmanagement
          </p>
          <p className="whatsnewmodal-text">
            Geeignet zum Betreiben von einer einzelnen Ladestation.
          </p>
        </li>
        <li>
          <p className="whatsnewmodal-text-headline">
            Basic-Lastmanagement
          </p>
          <p className="whatsnewmodal-text">
            Die verfügbare Ladeleistung wird nach Anzahl der Ladepunkte für jeden Ladepunkt gleichmäßig geteilt.
            Geeignet zum Betreiben von mehreren Ladepunkten, für die ausreichend Anschlussleistung für jeden Ladepunkt zur Verfügung steht. 
          </p>
        </li>
        <li>
          <p className="whatsnewmodal-text-headline">
            Downscaling-Lastmanagement
          </p>
          <p className="whatsnewmodal-text">
            Die verfügbare Leistung wird nach Anzahl der angeschlossenen Fahrzeuge für jeden <b>belegten</b> Ladepunkt
            gleichmäßig verteilt.
          </p>
          <p className="whatsnewmodal-text">
            Geeignet für Szenarien, wo mehrere Ladepunkte an einem Anschluss angeschlossen werden und der Anschluss nicht für alle Ladepunkte gleichzeitig zur Verfügung steht. 
          </p>
        </li>
        <li>
          <p className="whatsnewmodal-text-headline">
            Downscaling-Lastmanagement mit Rückkopplung
          </p>
          <p className="whatsnewmodal-text">
            Die verfügbare Leistung wird nach Anzahl der angeschlossenen Fahrzeuge für jeden belegten Ladepunkt
            gleichmäßig verteilt. Geeignet für Szenarien, wo mehrere Ladepunkte angeschlossen werden. 
          </p>
          <p className="whatsnewmodal-text">
            Darüber hinaus wird die tatsächlich benötigte Ladeleistung überwacht und so die Ladeleistung optimal an alle angeschlossenen
            Fahrzeuge verteilt, indem die nicht benötigte Ladeleistung an andere Fahrzeuge verteilt wird. 
          </p>
          <p className="whatsnewmodal-text">
            Geeignet für Szenarien, wo mehrere Ladepunkte an einem Anschluss angeschlossen werden und der Anschluss nicht für alle Ladepunkte gleichzeitig zur Verfügung steht und die Ladeleistung effizienter verteilt werden soll.  
          </p>
        </li>
        <li>
          <p className="whatsnewmodal-text-headline">
            Dynamisches Lastmanagement
          </p>
          <p className="whatsnewmodal-text">
            Das Downscaling-Lastmanagement wird um die Angabe der Smart Meter-Zähler ergänzt.
          </p>
          <p className="whatsnewmodal-text">
            Vom Vorteil, wenn die Anschlussleistung des Hauses auch berücksichtigt werden soll.   
          </p>
        </li>
      </ul>
    </Modal>
  );
}
