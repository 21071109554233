// ** -- This manages the user dialog -- **//

/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState } from "react";

// Style import
import "../../design/components/edgedeviceadddialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import {Button, Input, Modal } from "antd";
import {
  PlusCircleFilled
} from "@ant-design/icons";
/* --------------------
    
  USERDIALOG
  ------------------- */

//* -- HANDLE METHODS -- *//
// When "Abbrechen" button is clicked on edit modal



export default function UserManageDialog(props) {
  let userInformation;
  const [addUserStatus, setAddUserStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [nickname, setNickname] = useState("");
  const handleConfirm = async () => {
    setUserId("");
    // Now the route is the following:
    // First create user, after successful creation then it will be bound into the the electrican
    const body = {
      "user": {
        "email": userId.toLowerCase()
      }
    }
    await restservices.createUser(body).then(data => {
      if (data.status === 200){
        props.setUserList((prevState) => [
          ...prevState, userId,
        ]);
        setAddUserStatus("");
        props.setShowUserDialog(false);
        userInformation = data.data.email;
      }
      else {
        if (data.status === 409) 
        {
          setAddUserStatus("Gleicher User oder Nickname existiert bereits. Bitte prüfen.");
        }
        else{

          setAddUserStatus("Fehler beim Anlegen von User");
        }
      }
    })
  };

  const handleCancel =  () => {
    props.setShowUserDialog(false);
    setUserId("");
    setAddUserStatus("");
  };

  return(
    <Modal
      title="Benutzer anlegen" 
      visible={props.showUserDialog}
      //onCancel={handleCancel}
      width="800px"
      
      closable={false}
      footer={[
        <Button key="confirm" 
        icon={<PlusCircleFilled />} shape="round" onClick={handleConfirm}>
          Bestätigen
        </Button>,        
        <Button key="cancel" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>
      ]}
    >
      <div className="pooledit-container">
        {/* "Poolname" Input */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">Benutzer-E-Mail</p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Input
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="E-Mail-Adresse des Kunden eingeben"
          />
          <Input
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder="Nickname eingeben"
          />
        </div>

      </div>
      <div className="pooladddialog-error-text">
          {addUserStatus}
      </div>
    </Modal>
    
  );
}