/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/edgedeviceadddialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import { Button, Input, Modal, Select, Spin } from "antd";

/* --------------------
      EDGEDEVICEADDDIALOG
  ------------------- */
export default function EdgeDeviceAddDialog(props) {
  //* -- VARIABLES -- *//
  const {Option} = Select;
  //* -- STATES -- *//
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [client_name, setClient_name] = useState("");
  
  const [identity, setIdentity] = useState("");
  const [evseId, setEvseId] = useState("");
  const [max_amp, setMax_amp] = useState();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  
  useEffect(() => {
    //console.log(props.cpList); 
  }, [props.userList]);


  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel =  () => {
    props.setAddEdgeIsOpen(false);
  };

  const handleChangeEvseId = async(event) => {
    //console.log(event); 
    setIdentity(event);
    props.cpList.forEach((e)=> {
      if (e.identity === event){
        //console.log(e); 
        setEvseId(e.evse_id);
        setClient_name(e.friendly_name); 
        //console.log(event); 
      }
    })

  };

  const handleAddEdgeDevice = async() => {
    setLoading(true);
    setLoadingText("Ladestation hinzufügen...");
    // Check if all inputs are filled correctly
    if (
      //client_name !== "" &&
      //identity !== "" &&
      /^\d+$/.test(max_amp)
    ) {
      setError(false);
      setErrorText("");
      // first make a new edge device 
      //console.log(identity); 
      const newEdgeDevice = { 
        identity: identity, //Provisional, change to real identity later,
        phase_rotation: [1,2,3]
      }
      const newStation = {
        identity: identity,
        friendly_name: client_name,
        evse_id: evseId
      }

      // and then fetch the old pool

      const oldPool = await restservices.getPoolById(props.poolId);
      //console.log(oldPool); 
      // finally insert the new edge device to the array 
      const oldGroup = await restservices.getGroup(props.groupId);
      //console.log(oldGroup); 
      // then fetch the old group 
      var newEdgeList = oldPool.data.cp_config;
      newEdgeList.push(newEdgeDevice) ; 
      //console.log(props.cplist); 
      const newBody = 
      {
        pool: {
          "cp_config": newEdgeList
        }
      };
      //console.log(newBody);

      var newStationList = oldGroup.data.stations;
      newStationList.push(newStation);
      //console.log(newStationList); 
      const newGroup = {
        "group": {
          "stations": newStationList,
        }
      }
      let result = await restservices.updatePool(newBody, props.poolId);

      if (result.data !== "ERROR_SAME_CHARGEPOINT_EXISTS"){

        //restservices.setAmp(identity, max_amp);
        let lastKey = 0;
        var i;
        for (i=0; i<props.edgeDeviceList.length; i++){
          lastKey = props.edgeDeviceList[i].key;

        }
        props.setEdgeDeviceList((prevState) => [
          ...prevState,
          {
            id: identity,
            client_name: client_name,
            evse_id: evseId,
            max_amp: max_amp,
            key: lastKey + 1,
          },
        ]);
          
        setLoading(false);
        setMax_amp("");
        props.setAddEdgeIsOpen(false);
      }else{
        setError(true);
        setLoading(false);
        setErrorText("Gleiche EVSE ID wurde bereits konfiguriert. Prüfe bitte Poolkonfiguration.")
      }    
    } else {
      if (client_name === "" || client_name === undefined) {
        setError(true);
        setLoading(false);
        setErrorText("Bitte gültigen Namen für die Ladestation angeben");
      }
      // Check if "Max. Anschlussleistung" input is filled correctly
      else if (!/^\d+$/.test(max_amp) || max_amp === undefined) {
        setError(true);
        setErrorText("Bitte gültige max. Stromstärke angeben");
      } else {console.log("Fehler #EDAD_001");
      }

      
      setLoading(false);

    }
  };

  //* --  MAIN RETURN  -- *//
  return (

      <Modal
        title={"Ladestation anlegen"}
        visible={props.addEdgeIsOpen}
        onCancel={handleCancel}
        closable={false}
        width="800px"
        footer={[
          <Button key="back" shape="round" onClick={handleCancel}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleAddEdgeDevice}
          >
            Ladestation anlegen
          </Button>,
        ]}
      >
        <Spin 
          spinning={loading}
          tip={loadingText}
        >
          <div className="edgedeviceadddialog-container">
            {/* "Edge-Device Name" Input */}
            



            {/* "EVSE ID" Dropdown */}

            <div className="edgedeviceadddialog-item-left">
              <div>
                <p className="edgedeviceadddialog-input-description">EVSE ID </p>
              </div>
            </div>
            <div className="edgedeviceadddialog-item-right">
              <Select
                showSearch
                value={identity}
                onChange={(e) => handleChangeEvseId(e)}
                placeholder="EVSE ID auswählen"
                filterOption={(input, option) =>
                  (option.props.children).toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%" }}           
              >
                {      
                  props.cpList.map((item, index) => (
                    <Option value={item.identity} key={index}>{item.evse_id}</Option>
                  ))
                }     
              </Select>
            </div>


            {/* "max. Anschlussleistung" Input */}
            <div className="edgedeviceadddialog-item-left">
              <div>
                <p className="edgedeviceadddialog-input-description">
                  Max. Stromstärke
                </p>
              </div>
            </div>
            <div className="edgedeviceadddialog-item-right">
              <Input
                value={max_amp}
                onChange={(e) => setMax_amp(e.target.value)}
                suffix="Ampere"
                placeholder="Max. Stromstärke eingeben"
              />
            </div>
          </div>
        
        </Spin>
        {/* Error text with dynamic render scss class */}
        <p
          className={
            error
              ? "edgedeviceadddialog-error-text"
              : "edgedeviceadddialog-error-text-hidden"
          }
        >
          {errorText}
        </p>
      </Modal>
  );
}
