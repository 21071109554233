/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/whatsnewmodal.scss";

// Ant design import
import { Button, Checkbox, Modal } from "antd";

/* --------------------
      WhatsNewModal
  ------------------- */
export default function WhatsNewModal(props) {
  //* -- VARIABLES -- *//

  //* -- STATES -- *//
  const [isOpen, setIsOpen] = useState(props.showWhatsNewModal);
  const [isChecked, setIsChecked] = useState(false);

  //* -- HANDLE METHODS -- *//
  // When "Schließen" button is clicked on edit modal, dependent from checkbox
  const handleClose = (props) => {
    if (isChecked) {
      localStorage.setItem("showWhatsNewModal", false);
    } else {
    }
    setIsOpen(false);
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Version 1.2" +
        " ist jetzt verfügbar"
      }
      className="xxxx"
      visible={isOpen}
      closable={false}
      footer={[
        <Checkbox
          key="checked"
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          style={{ marginRight: "20px" }}
        >
          Nicht erneut anzeigen
        </Checkbox>,

        <Button key="close" shape="round" type="primary" onClick={handleClose}>
          Schließen
        </Button>,
      ]}
    >
      <p className="whatsnewmodal-headline">Hallo {props.identity}</p>
      <p className="whatsnewmodal-text">
        Es gibt gute Neuigkeiten! Das Elektriker Dashboard gibt es nun in der
        neuen Version {process.env.REACT_APP_SOFTWARE_VERSION}. Nachfolgend
        kannst du dir die Neuerung genauer anschauen:
      </p>
      <ul>
        <li>
          <p className="whatsnewmodal-text-headline">
          Performancesteigerung
          </p>
          <p className="whatsnewmodal-text">
            Jetzt geht das Laden von Pools und deren Ladestation viel schneller.
          </p>
        </li>
        <li>
          <p className="whatsnewmodal-text-headline">
            Fehlerbehebungen und weitere Optimierungen
          </p>
        </li>
      </ul>
    </Modal>
  );
}
