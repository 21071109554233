import React from "react";

// Style import
import "../../design/components/pooladddialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import { Button,  Modal} from "antd";

/* --------------------
      POOLADDDIALOG
  ------------------- */
export default function PoolDeleteAlertDialog(props) {

  const handleCancel = () => {

    props.setDeletePoolIsOpen(false); 
  }

  const handleDeletePool = async () => {

    console.log(props.selectedPoolId); 
    await restservices.deletePool(props.selectedPoolId).then(
      data => {
      }
    );
    let tempPoolList = [...props.poolList];
    //Simply reload the pool list
    tempPoolList.forEach((pool,i) =>{
      if (props.selectedPoolId === pool._id){
        tempPoolList.splice(i, 1);
      }
    })
    props.setPoolList(tempPoolList);
    props.setEdgeDeviceList([]);
    props.setDeletePoolIsOpen(false); 
  }
  return (
    <Modal
      title= {"Sind Sie sicher, dass Sie den Pool löschen wollen?"}
      visible={props.deletePoolIsOpen}
      onCancel={handleCancel}
      width="800px"
      closable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          shape="round"
          danger
          onClick={handleDeletePool}
        >
          Ja, Pool löschen
        </Button>,
        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>
        
      ]}
      
    >
     Nach dem Löschen wird der Pool nicht mehr wiederhergestellt werden können!  
    </Modal>
  );
}