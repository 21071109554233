/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../design/pages/dashboard.scss";

// Ant Design import
import { Dropdown, Layout, Menu, Row, Col, Avatar, Spin } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
// Component import

import PoolTable from "../components/pool/PoolTable";
import WhatsNewModal from "../components/modals/WhatsNewModal";
import NonElectricianModal from "../components/modals/NonElectricianModal";
// Component for user management
import UserManageDialog from "../components/user/UserManageDialog";
import ReportDialog from "../components/report/ReportDialog";

// Category Management
// Authentification
import Cookies from "js-cookie";
import { restservices } from "../rest-services.js";

// Ressource import
import chargeIQLogo from "../resource/20200923_chargeIQ_Logo_white_small.png";
import { useAuth0 } from "@auth0/auth0-react";
/* ------------
      DASHBOARD  
  ------------ */



let { Socket } = require('phoenix-channels');
  
export default function Dashboard(props) {
  //* -- VARIABLES -- */

  const { Header, Footer, Sider, Content } = Layout;
  const [accessToken, setAccessToken] = useState("");

  //* -- USER DIALOG STATES -- *//
  const [showUserDialog, setShowUserDialog] = useState(false);
  const [userList, setUserList] = useState([]);

  //* -- POOL STATES -- *//
  const [selectedPool, setSelectedPool] = useState({});
  const [selectedPoolKey, setSelectedPoolKey] = useState(-1);
  const [selectedPoolId, setSelectedPoolId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [selectedPoolUserId, setSelectedPoolUserId] = useState("");

  //* -- Edge device states -- *//
  const [edgeDeviceList, setEdgeDeviceList] = useState([]);
  const [evseIdSelection, setEvseIdSelection] = useState([]);
  const [selectedEdgeDevice, setSelectedEdgeDevice] = useState({});
  const [selectedEdgeDeviceId, setSelectedEdgeDeviceId] = useState("");
  const [selectedEdgeDeviceKey, setSelectedEdgeDeviceKey] = useState(0);

  //* -- MISC STATES --*//
  const [showNonElectricianModal, setShowNonElectricianModal] = useState(false);
  const [poolList, setPoolList] = useState([]);
  const [cpList, setCpList] = useState([]); //Used in "Add edge device" dropdown 

  //* -- CATEGORY STATES -- *//

  const [categoryList, setCategoryList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [categoryListDisabled, setCategoryListDisabld] = useState(false);
  
  //* -- SPIN STATES --*//
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  //* -- AUTH0 RELATED STATES --*//
  const [electricianId, setElectricianId] = useState("");
  const [centralElectricianId, setCentralElectricianId] = useState("");
  const [groupList, setGroupList] = useState([]); 

  //* -- REPORT DIALOG STATES --*//
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [liveData, setLiveData] = useState([]); //holds the centralized liveDatas]
  const [singleLiveData, setSingleLiveData] = useState([]); //holds the centralized liveDatas]
  const [statistics, setStatistics] = useState([]); 


  const { logout, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  useEffect( () => {
    if (isAuthenticated) {
      let tempAccessToken = "";
      //console.log(process.env.REACT_APP_WEBSOCKET_URL + "/realtime");

      getAccessToken().then((result) => {
        tempAccessToken = result;
        //console.log(tempAccessToken);
        setAccessToken(tempAccessToken); 
        let wss = new Socket(process.env.REACT_APP_WEBSOCKET_URL + "/realtime", 
        //let wss = new Socket(websocketurl+ "/realtime", 
        {
          params: { user_token: tempAccessToken },
        });

        wss.connect();
        //console.log(wss); 
        setShowUserDialog(false);
        const auth0UserId= user.sub;

        restservices.getUserWithExtId(auth0UserId).then((result) => {
          if (checkIfMembershipContainsElectrician(result.data.memberships) === true) {
            const electricianId = result.data.id; 
            setCentralElectricianId(electricianId);  

            restservices.getUserWithCentralUserId(electricianId).then((result) => {
              //console.log(result); 
              const filteredGroupsByMembership = result.data.data.memberships.filter(member => member.role === 'electrician');
              //console.log(filteredGroupsByMembership); 
              const filteredGroupsByAspects = filteredGroupsByMembership.filter(group => group.group.aspects.every (a => a !== 'lm')); //temporarly, change to lm later on
              
              const uniqueGroups = [...new Map(filteredGroupsByAspects.map((item) => [item["group_id"], item])).values(),];
              //console.log(uniqueGroups);
              uniqueGroups.forEach((data) => {
                let channel = wss.channel(`group:${data.group_id}`, {});
                channel
                  .join()
                  .receive("ok", () => {
                    //console.log("Websocket connected");
                  })
                  .receive("error", (resp) => {
                    //console.log("Unable to connect to Websocket", resp);
                  })
                  .receive("timeout", () => {
                    //console.log("Networking issue. Still waiting... to add to group ", data.group_id);
                  });

                channel.on("change_chargepoints", (payload) => {
                  //setChangeChargepoints(payload);
                });
                channel.on("update_chargepoint", (payload) => {
                  //setUpdateChargepoint(payload);
                  //console.log("updateChargepoint: ", payload);
                  updateChargepoint(payload); 
                });
                channel.on("new_process", (payload) => {
                  //setNewProcess(payload);
                });
                channel.on("update_process", (payload) => {
                  //setUpdateProcess(payload);
                  
                });
                //console.log(channel);
                // And then fetch initial data 

              })
              setGroupList(uniqueGroups); 
              loadAllCategorys(electricianId); 


            });
            restservices.getChargepointsWithUserCentral(electricianId).then((result) => {
              setCpList(result.data); 
            })
            restservices.getAllPoolsFromElectrician(electricianId).then((result) => {
              let liveDataArray = [] ;
              result.data.forEach((data) => {

                restservices.getPoolById(data.id).then((pool) => {
                  setLiveData((prevState) => [
                    ...prevState, {
                      id: pool.data.id,
                      chargepoints: pool.data.chargepoints,
                    }

                  ])
                })
              })
              //console.log(liveDataArray);
            })
          }
          else{
            setShowNonElectricianModal(true) ; 
          }
         });
        }
      );
      setElectricianId(user.sub);

      //And then connect to wss 
      
      
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let nextLiveData = [...liveData]; 
    ////console.log("previous live data was ", nextLiveData); 
    
    const finalLiveData = nextLiveData.map((e) => ({
      ...e,
      chargepoints: e.chargepoints.map((cp) => {
        if (cp.identity === singleLiveData.identity) {
          return{
            ...cp,
            status: singleLiveData.status,
            measurements: singleLiveData.measurements,
          };
        }
        else{
          return {...cp};
        }
      }

      )
      
    }))

    ////console.log("new live data is ", finalLiveData); 
    setLiveData(finalLiveData); 
  }, [singleLiveData]);

  const updateChargepoint = (payload) => {
    ////console.log(payload); 
    setSingleLiveData(payload); 

  }

  const loadAllCategorys =  (electricianId) => {
    //console.log(liveData); 
    let tempCategoryList = []; 
    restservices
    .getAllPoolsFromElectrician(electricianId) // TODO: Replace with real electrician id 
    .then((data) => {
      data.data.forEach((e) => {
        if (e.category !== null) {
          tempCategoryList.push(e.category); 
        }
      })
      let uniqueCategory = [...new Set(tempCategoryList)];
      setCategoryList(uniqueCategory); 
    })
    .catch((err) => console.error(err));
  }
  const checkIfMembershipContainsElectrician = (memberships) => {

    //console.log(memberships); 
    if (memberships.length > 0){
      let hasElectricianRole = false; 
      memberships.some(e => {
        if (e.role === "electrician"){
          hasElectricianRole = true;
          //console.log(e); 
          return hasElectricianRole; 
        }
      })
      return hasElectricianRole; 
    }
    else{
      return false;
    }
  }
  const getAccessToken = async (scope) => {
    // TODO domain should be a env VAR
    let accessToken;
    if (isAuthenticated) {
      try {
        accessToken = await getAccessTokenSilently({
          audience: auth0Audience,
          scope: scope,
        });

        const expires = 60 * 60 * 1000;
        const inOneHour = new Date(new Date().getTime() + expires);
        Cookies.set("access_token", accessToken, {
          expires: inOneHour,
          SameSite: "Lax",
        });
      } catch (e) {
      }
    }

    return accessToken;
  };
  //* -- USE EFFECT -- *//
  useEffect(() => {}, []);

  //* -- HANDLE METHODS -- *//

  const handleShowReport = async () => {
    setShowReportDialog(true);
    let statisticsArray = []; 
    restservices.getAllPoolsFromElectrician(centralElectricianId).then((result) => {
      ////console.log(result); 
      let countArray = {
        NONE: 0,
        BASIC: 0,
        DOWNSCALING: 0,
        DOWNSCALING_WITH_BACKCOUPLING:  0,
        DYNAMIC: 0,
        DYNAMIC_WITH_BACKCOUPLING: 0,
        POOL_COUNT: 0
      }
      result.data.forEach((data) => {
        //console.log(data); 
        // Generate reports per pool
        const currentPool = {
          id: data.id,
          name: data.group.name,
          mode: data.mode,
          cp_count: data.cp_config.length,
        }
        //console.log(currentPool); 
        statisticsArray.push(currentPool); 
        // Count pools
        countArray.POOL_COUNT +=1; 
        switch (data.mode) {
          case "NONE":
            countArray.NONE +=1;
            break;
          case "BASIC":
            countArray.BASIC +=1;
            break;
          case "DOWNSCALING":
            countArray.DOWNSCALING +=1;
            break;
          case "DOWNSCALING_WITH_BACKCOUPLING":
            countArray.DOWNSCALING_WITH_BACKCOUPLING +=1;
            break;
          case "DYNAMIC":
            countArray.DYNAMIC +=1;
            break;
          case "DYNAMIC_WITH_BACKCOUPLING":
            countArray.DYNAMIC_WITH_BACKCOUPLING +=1;
            break;
        }
      } )
      //console.log(countArray); 

      const dataSource = [
        
        {
          key: '1',
          type: 'kein Lastmanagement',
          count: countArray.NONE,
        },
        {
          key: '2',
          type: 'Basic',
          count: countArray.BASIC,
        },
        {
          key: '3',
          type: 'Downscaling',
          count: countArray.DOWNSCALING,
        },
        {
          key: '4',
          type: 'Downscaling m. Rückk.',
          count: countArray.DOWNSCALING_WITH_BACKCOUPLING,
        },
        {
          key: '5',
          type: 'Dynamisch',
          count: countArray.DYNAMIC,
        },
        {
          key: '6',
          type: 'Dynamischm. Rückk.',
          count: countArray.DYNAMIC_WITH_BACKCOUPLING,
        },
        {
          key: '7',
          type: 'Summe Anzahl Pools',
          count: countArray.POOL_COUNT,
        },

      ];
      //console.log(dataSource); 
      setReportList(dataSource);
      setStatistics(statisticsArray); 
    })
  };
  const handleMouseOver = () => {
  };

  const loadAllPoolsByCategory = async (e)  => {
    //console.log(liveData); 
    setPoolList([]);
    setCategoryListDisabld(true);
    setLoading(true);
    setLoadingText("Laden aller Pools...");
    //console.log(centralElectricianId);


    let pools = [];
    await restservices
      .getAllPoolsFromElectrician(centralElectricianId) // TODO: Replace with real electrician id 
      .then((data) => {
        //console.log(data);
        pools = data.data;
      })
      .catch((err) => console.error(err));

    //! Only for Testing, in final version call in restservice should be used
    //TODO: Restservice method needs to be adapted to new data structure (user and client)
    if (pools.length > 0) {
      pools.forEach(async (pool, i) => {
        
        if (pool.category === e) {
          // Reformatting the load management labeling
          let lm_mode = "";
          if (pool.mode === "NONE" || pool.mode === "kein Lastmanagement") {
            lm_mode = "kein Lastmanagement";
          } else if (pool.mode === "BASIC") {
            lm_mode = "Basic";
          } else if (pool.mode === "DOWNSCALING" || pool.mode === "Downscaling") {
            lm_mode = "Downscaling";
          } else if (
            pool.mode === "DOWNSCALING_WITH_BACKCOUPLING" ||
            pool.mode === "Downscaling m. Rückk."
          ) {
            lm_mode = "Downscaling m. Rückk.";
          } else if (pool.mode === "DYNAMIC" || pool.mode === "Dynamisch") {
            lm_mode = "Dynamisch m. Rückk.";
          } else if (
            pool.mode === "DYNAMIC_WITH_BACKCOUPLING" ||
            pool.mode === "Dynamisch m. Rückk."
          ) {
            lm_mode = "Dynamisch m. Rückk.";
          } else {
            lm_mode = pool.mode;
          }
          let groupName;
          let groupId; 
          const poolId = pool.id;
          //console.log(poolId);
          await restservices
            .getPoolById(poolId)
            .then((data) => {
              //console.log(data.data);
              groupName = data.data.group.name; // TODO: Fix to final data mode l
              groupId = data.data.group.id;
              //console.log(groupName); 
            })
            .catch((err) => console.error(err));
          setLoadingText("Laden aller zugewiesenen Kunden zum Elektriker...");
          const userId = pool.user_id;
          //console.log(userId) ; 

          setLoadingText("Laden des Poolstatus...");

          setPoolList((prevState) => [
            ...prevState,
            {
              _id: pool.id,
              key: i + 1,
              group_id: groupId,
              pool_name: groupName,
              load_management_mode_text: lm_mode,
              load_management_mode: pool.mode,
              activeText: pool.active === true ? "aktiv" : "inaktiv",
              active:  pool.active,
              max_amp: pool.max_power,
              category: pool.category,
            },
          ]);
        } 
        
      });
    }
    setLoading(false);
    setCategoryListDisabld(false);
  };


  const loadAllPools = async () => {
    setPoolList([]);
    setCategoryListDisabld(true);
    setLoading(true);
    setLoadingText("Laden aller Pools...");
    //console.log(centralElectricianId);


    let pools = [];
    await restservices
      .getAllPoolsFromElectrician(centralElectricianId) // TODO: Replace with real electrician id 
      .then((data) => {
        //console.log(data);
        pools = data.data;
      })
      .catch((err) => console.error(err));

    //! Only for Testing, in final version call in restservice should be used
    //TODO: Restservice method needs to be adapted to new data structure (user and client)
    if (pools.length > 0) {
      pools.forEach(async (pool, i) => {
        
        //console.log(pool); 
        // Reformatting the load management labeling
        let lm_mode = "";
        if (pool.mode === "NONE" ||pool.mode === "None" ||  pool.mode === "kein Lastmanagement") {
          lm_mode = "kein Lastmanagement";
        } else if (pool.mode === "BASIC") {
          lm_mode = "Basic";
        } else if (pool.mode === "DOWNSCALING" || pool.mode === "Downscaling") {
          lm_mode = "Downscaling";
        } else if (
          pool.mode === "DOWNSCALING_WITH_BACKCOUPLING" ||
          pool.mode === "Downscaling m. Rückk."
        ) {
          lm_mode = "Downscaling m. Rückk.";
        } else if (pool.mode === "DYNAMIC" || pool.mode === "Dynamisch") {
          lm_mode = "Dynamisch m. Rückk.";
        } else if (
          pool.mode === "DYNAMIC_WITH_BACKCOUPLING" ||
          pool.mode === "Dynamisch m. Rückk."
        ) {
          lm_mode = "Dynamisch m. Rückk.";
        } else {
          lm_mode = pool.mode;
        }

        setLoadingText("Laden aller zugewiesenen Kunden zum Elektriker...");
        const userId = pool.user_id;
        let userEmail;
        
        /*
        await restservices
          .getUserWithUserId(userId)
          .then((data) => {
            userEmail = data;
          })
          .catch((err) => console.error(err));
        ////console.log(userEmail);
        */
        await restservices
          .getUserWithCentralUserId(centralElectricianId)
          .then((data)=> {
            userEmail = data.email; 
          })
          .catch((err) => console.error(err));

        //console.log(userEmail);
        let groupName;
        let groupId; 
        const poolId = pool.id;
        //console.log(poolId);
        await restservices
          .getPoolById(poolId)
          .then((data) => {
            groupName = data.data.group.name; // TODO: Fix to final data mode l
            groupId = data.data.group.id;
          })
          .catch((err) => console.error(err));

        setLoadingText("Laden des Poolstatus...");

        setPoolList((prevState) => [
          ...prevState,
          {
            _id: pool.id,
            key: i + 1,
            group_id: groupId,
            pool_name: groupName,
            userId: pool.user_id,
            user_email: userEmail,
            load_management_mode: pool.mode,
            load_management_mode_text:lm_mode,
            activeText: pool.active === true ? "aktiv" : "inaktiv",
            active:  pool.active,
            max_amp: pool.max_power,
            category: pool.category,
          },
        ]);
      });
    }
    setLoading(false);
    setCategoryListDisabld(false);
  };


  const handleClickCategory = (e) => {
    setCurrentCategory(e.key);
    loadAllPoolsByCategory(e.key);

    //and select the first pool if 
  };

  const handleManageUsers = () => {
    setShowUserDialog(true);
  };
  const handleClickAllCategory = () => {
    loadAllPools();
  };

  //* AVATAR MENU *//
  const electricianMenu = (
    <Menu>
      <Menu.Item onClick={handleManageUsers}>
        <div className="dashboard-header-menu">
          <p className="dashboard-header-menu-text">Benutzer hinzufügen</p>
        </div>
      </Menu.Item>     
      
      <Menu.Item onClick={handleShowReport}>
        <div className="dashboard-header-menu">
          <p className="dashboard-header-menu-text">Zusammenfassung anzeigen</p>
        </div>
      </Menu.Item>     
      <Menu.Item onClick={() => logout({ returnTo: window.location.origin })}>
        <div className="dashboard-header-menu">
          <LogoutOutlined className="dashboard-header-menu-logout-icon" />
          <p className="dashboard-header-menu-logout-text">Abmelden</p>
        </div>
      </Menu.Item>
    </Menu>
  );

  //* --  MAIN RETURN  -- *//
  return (
    <Layout>
      {/* Header menu bar */}
      <Header className="dashboard-header">
        <Row>
          <Col>
            <img
              src={chargeIQLogo}
              alt="chargeIQ GmbH"
              className="dashboard-header-logo"
            />
            {"TEst " } 
          </Col>
          {/* Separator between left and right */}
          <Col flex="auto" />
          <Col onMouseEnter={handleMouseOver}>
            <Dropdown overlay={electricianMenu}>
              <div>
                <h3 className="dashboard-header-avatar-text">{user.email}</h3>
                <Avatar id="dashboard-header-avatar">
                  {user.nickname.match(/\b(\w)/g)}
                </Avatar>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider className=".dashboard-sidebar-wrapper">
          <Menu
            style={{ width: 200 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
          >
            <Menu.Item
              onClick={() => handleClickAllCategory()}
              disabled={categoryListDisabled}
            >
              Alle Pools anzeigen
            </Menu.Item>
            {categoryList.map((item) => (
              <Menu.Item
                onClick={(e) => handleClickCategory(e)}
                key={item}
                disabled={categoryListDisabled}
              >
                {item}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>

        {/* Content of dashboard */}
        <Layout>
          <Content>
            <Spin
              spinning={loading}
              tip={loadingText}
              size="large"
              style={{ height: "50%" }}
            >
              {/* User Content of dashboard */}
              <div className="dashboard-content-wrapper">
                
                <UserManageDialog
                  userList={userList}
                  electricianId={electricianId}
                  setUserList={setUserList}
                  showUserDialog={showUserDialog}
                  setShowUserDialog={setShowUserDialog}
                />
                {/* Content of Category */}
                <PoolTable
                  userList={userList}
                  poolList={poolList}
                  setPoolList={setPoolList}
                  category={currentCategory}
                  categoryList={categoryList}
                  setCategoryList={setCategoryList}
                  electricianId={centralElectricianId}
                  // * -- Pool list -- * //
                  selectedPool={selectedPool}
                  setSelectedPool={setSelectedPool}
                  selectedPoolKey={selectedPoolKey}
                  setSelectedPoolKey={setSelectedPoolKey}
                  selectedPoolId={selectedPoolId}
                  setSelectedPoolId={setSelectedPoolId}
                  selectedPoolUserId={selectedPoolUserId}
                  setSelectedPoolUserId={setSelectedPoolUserId}
                  groupList={groupList}
                  setGroupList={setGroupList}
                  groupId={groupId}
                  setGroupId={setGroupId}
                  // * -- Edge device list props  * //
                  edgeDeviceList={edgeDeviceList}
                  setEdgeDeviceList={setEdgeDeviceList}
                  evseIdSelection={evseIdSelection}
                  setEvseIdSelection={setEvseIdSelection}
                  selectedEdgeDevice={selectedEdgeDevice}
                  setSelectedEdgeDevice={setSelectedEdgeDevice}
                  selectedEdgeDeviceId={selectedEdgeDeviceId}
                  setSelectedEdgeDeviceId={setSelectedEdgeDeviceId}
                  selectedEdgeDeviceKey={selectedEdgeDeviceKey}
                  setSelectedEdgeDeviceKey={setSelectedEdgeDeviceKey}
                  cpList= {cpList}
                  setCpList = {setCpList}
                  accessToken = {accessToken}
                  liveData = {liveData}

                />
                <br />
                <WhatsNewModal
                  electricianId={props.electricianId}
                  showWhatsNewModal={false}
                />
                <NonElectricianModal
                  showNonElectricianModal={showNonElectricianModal}
                  setShowNonElectricianModal={setShowNonElectricianModal}
                />
                <ReportDialog
                  showReportDialog={showReportDialog}
                  setShowReportDialog={setShowReportDialog}
                  reportList={reportList}
                  setReportList={setReportList}
                  statistics={statistics}
                />
              </div>
            </Spin>
          </Content>
        </Layout>
      </Layout>

      {/* Footer with version description */}
      <Footer style={{ textAlign: "center" }}>
        <p className="dashboard-footer-text">
          Copyright © chargeIQ GmbH {new Date().getFullYear()}
        </p>
      </Footer>
    </Layout>
  );
}
