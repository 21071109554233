/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/whatsnewmodal.scss";

// Ant design import
import { Button, Checkbox, Modal } from "antd";

/* --------------------
      WhatsNewModal
  ------------------- */
export default function DynamicLoadManagementDropdownModal(props) {
  //* -- VARIABLES -- *//

  const handleClose = () => {
    props.setIsOpen(false);
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Dynamisches Lastmanagement"
      }
      className="xxxx"
      visible={props.isOpen}
      footer={[
        <Button key="close" shape="round" type="primary" onClick={handleClose}>
          Schließen
        </Button>,
      ]}
    >
      <p className="whatsnewmodal-text">
        Hinweis: Dynamisches Lastmanagement benötigt einen MODBUS TCP-Zähler, der an Ladestation mit connectIQ verbunden ist. 
      </p>
      <p>
        
        Bitte kontaktiere uns für weiteres Vorgehen unter <b>support@chargeiq.de</b>, damit wir die gewünschten Einstellungen zur Verbindung mit dem MODBUS TCP-Zähler konfigurieren können. 
      </p>
           
    </Modal>
  );
}
