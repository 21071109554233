/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// Style import
import "../design/app/app.less";
import "../design/app/app.scss";
import Dashboard from "../pages/Dashboard.js";
// Component import
import Login from "../pages/Login.js";
// Services import
import { useAuth0 } from "@auth0/auth0-react";

require('dotenv').config();

//console.log(process.env.REACT_APP_AUTH0_DOMAIN); 
//console.log(process.env.REACT_APP_CENTRIQ_URL); 
/* ------------
      APP  
  ------------ */
export default function App() {

  const { isAuthenticated } = useAuth0();


  //* --  MAIN RETURN  -- *//
  return (
    <div className="appRoot">
      
      <div className="font-face-gm">
      <BrowserRouter>
        <Switch>
          <Route
            // exact
            path="/dashboard"
            render={() =>
              isAuthenticated ? (
                <Dashboard
                />
              ) : (
                <Login />
              )
            }
          />

          <Route
            render={() =>
              !isAuthenticated ? (
                <Login
                />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
        </Switch>
      </BrowserRouter>
      </div>
    </div>
  );
}
