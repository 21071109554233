/* -------------
      IMPORTS  
  -------------- */
// React import
// Ant design import
import { Button,  Modal, Table, DatePicker } from "antd";

import React, { useEffect, useState } from "react";
// Style import
import "../../design/components/pooladddialog.scss";
// Services import
import { restservices } from "../../rest-services.js";

import fileDownload from 'js-file-download'
import { write } from "@popperjs/core";
import stringify from 'csv-stringify';
import fs from 'fs';
/* --------------------
      POOLADDDIALOG
  ------------------- */
export default function ReportDialog(props) {


  

  const { RangePicker } = DatePicker;
  //* -- VARIABLES -- *//
  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel = () => {
    props.setShowReportDialog(false);
  };
  const [startDate, setStartDate] = useState(""); 
  const [endDate, setEndDate] = useState(""); 
  const [statisticsData, setStatisticsData] = useState([]); 

  useEffect(() => {
    //console.log("statistics changed"); 
    let nextStatistics = [...props.statistics];
    setStatisticsData([]); 
    nextStatistics.forEach((e, index) => {
      restservices.getPoolById(e.id).then((data) => {
        if ((data.data.chargepoints.length) > 0){
          let newChargepointCount = 0; 
          data.data.chargepoints.forEach((cp) => {
            if ((new Date(cp.time_added).getTime() >= startDate )&&(new Date(cp.time_added).getTime() <= endDate) ){
              newChargepointCount+=1;
            }
          })
          nextStatistics[index].new_chargepoint = newChargepointCount; 
          //console.log(nextStatistics[index]); 
          setStatisticsData((prevState) => [
            ...prevState,
            nextStatistics[index],
          ])
          //console.log(statisticsData); 
        }
      })
    })
 
  }, [props.statistics]);
  const handleDownloadProtocol = () => {
    // Convert statisticsData in states to CSV Table
    let finalStream = "Name;LM-Modus;Summe Anzahl Ladepunkt;Summe Ladepunkte Neuzugang\n"; 
    statisticsData.forEach((data) => {
      const row = `${data.name};${data.mode};${data.cp_count};${data.new_chargepoint}\n`;
      //console.log(row);
      finalStream += row; 
    })
    fileDownload(finalStream, "report.csv"); 
    // FInally donwload CSV
  };  
  
  const handleChangeDate = (e) => {
    //console.log(e); 
    if (e != undefined) {

      const startTimeDate = e[0].startOf('day').format("X");
      const endTimeDate = e[1].endOf('day').format("X");
      //console.log(startTimeDate);
      //console.log(endTimeDate);
      setStartDate(startTimeDate); 
      setEndDate(endTimeDate);
    }

  };  
  
  const columns = [
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Anzahl',
      dataIndex: 'count',
      key: 'count',
    }
  ];

  useEffect(()=> {
  })

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={"Elektriker-Zusammenfassung "}
      onCancel={handleCancel}
      open={props.showReportDialog}
      width="800px"
      footer={[
        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,
      ]}
    >

      <Table dataSource={props.reportList} columns={columns} />
     
      <Button key="back" shape="round" onClick={handleDownloadProtocol}>
        Protokoll herunterladen
      </Button>
      <RangePicker onChange={(e)=> handleChangeDate(e)} />
  
    </Modal>
  );
}
