/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";

// Style import
import "../../design/components/edgedeviceeditdialog.scss";

// Services import
import { restservices } from "../../rest-services.js";

// Ant design import
import { Button, Input, Modal, Spin, Select } from "antd";

/* --------------------
      EDGEDEVICEEDITDIALOG
  ------------------- */
export default function EdgeDeviceEditDialog(props) {
  //* -- VARIABLES -- *//
  const {TextArea} =Input;
  const { Option } = Select;
  //* -- STATES -- *//
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [client_name, setClient_name] = useState("");
  const [comment, setComment] = useState("");
  const [client_id, setClient_Id] = useState("");
  const [max_amp, setMax_amp] = useState();
  const [phaseL0, setPhaseL0] = useState(0);
  const [phaseL1, setPhaseL1] = useState(1);
  const [phaseL2, setPhaseL2] = useState(2);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  //* -- COMPONENT UPDATE -- *//
  // When selected edge device in parent table changes, the provided prop and thus these components states will be updated
  useEffect(() => {
    if ((props.selectedEdgeDevice !== undefined) && (props.selectedEdgeDevice !== {}) ) {
      setClient_name(props.selectedEdgeDevice.client_name);
      setComment(props.selectedEdgeDevice.comment);

      //console.log(props.selectedEdgeDevice); 
      setMax_amp(props.selectedEdgeDevice.max_amp);
      if (props.selectedEdgeDevice.hasOwnProperty('phase_rotation')){

      setPhaseL0(props.selectedEdgeDevice.phase_rotation[0]);
      setPhaseL1(props.selectedEdgeDevice.phase_rotation[1]);
      setPhaseL2(props.selectedEdgeDevice.phase_rotation[2]);
      }
    }
  }, [props.selectedEdgeDevice]);


  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel = () => {
    props.setEditEdgeIsOpen(false);
  };

  // When "Edge-Device anlegen" button is clicked on edit modal
  const handleSaveEdgeDevice = async () => {
    // Check if all inputs are filled correctly
    if (
      client_name !== "" 
    ) {
      setLoading(true);
      setLoadingText("Änderungen speichern...");
      setError(false);
      setErrorText("");
      ////console.log(client_name + " " + client_id +  " " + max_amp);
      let phaseRotation = [phaseL0, phaseL1, phaseL2];
      let phaseRotationText = "";
      phaseRotation.forEach((pow, i) => {
        phaseRotationText += pow;
        if (i <= 1) {
          phaseRotationText += " / ";
        }
      });
      //console.log(props.selectedEdgeDevice);
      
      const oldPool = await restservices.getPoolById(props.poolId);
      //console.log(oldPool); 
      // finally insert the new edge device to the array 
      //console.log(props.selectedEdgeDevice); 
      var newEdgeList = oldPool.data.cp_config;
      //console.log(newEdgeList); 
      let finalEdgeList = newEdgeList;
      var index = newEdgeList.findIndex(x => x.identity==props.selectedEdgeDevice.id); 
      //console.log(index); 
      if (index === -1) {
        finalEdgeList.push({
          identity: props.selectedEdgeDevice.id,
          max_amps: max_amp,
          phase_rotation: phaseRotation,
        }
        );
      }
      else{

        finalEdgeList = finalEdgeList.map((edge, index, allEdgeDevices) => {

          //console.log(allEdgeDevices)
  
          if ((props.selectedEdgeDevice.id=== edge.identity)){ 
            //changes local
            return {
              ...edge,
              identity: props.selectedEdgeDevice.id,
              max_amps: max_amp,
              phase_rotation: phaseRotation,
            }
          }
          else {
            return {
              ...edge,
            }
          }         
        });   
      }
 

      const newBody = 
      {
        pool: {
          "cp_config":finalEdgeList,
        }
      };
      //console.log(newBody);
      restservices.updatePool(newBody, props.poolId).then(data => {
        //console.log(data);

        let nextEdgeDeviceList = props.edgeDeviceList.map((e, index) => {
          if (e.key === props.selectedEdgeDeviceKey[0]){
            return {
              ...e,
              comment: comment,
              client_name: client_name,
              phase_rotation: phaseRotation,
              phase_rotation_text: phaseRotationText,
              max_amp: max_amp,
            }
          }
          else{
            return {
              ...e,

            }
          }
        });
        ////console.log(props.edgeDeviceList)
        
        props.setEdgeDeviceList(nextEdgeDeviceList);  
        setLoading(false);
        props.setEditEdgeIsOpen(false);
      });
      

    } else {
      // Check if "Edge-Device Name" input is filled correctly
      if (client_name === "" || client_name === undefined) {
        setError(true);
        setErrorText("Bitte gültigen Namen für das Edge-Device angeben");
        ////console.log("client_name fehlerhaft");
      }

      else 
      {
        console.log("Fehler #EDAD_001");
      }
    }
  };



  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={"Ladepunkt konfigurieren für " + props.selectedEdgeDevice.friendly_name }
      visible={props.editEdgeIsOpen}
      closable={false}
      onCancel={handleCancel}
      width="800px"
      footer={[

        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,

        <Button
          key="submit"
          shape="round"
          type="primary"
          onClick={handleSaveEdgeDevice}
        >
          Änderungen speichern
        </Button>,
      ]}
    >
      <Spin 
        spinning={loading}
        tip={loadingText}
        style={{ height: '50%' }}
      >
      <div className="edgedeviceeditdialog-container">

        {/* "Edge-Device Name" Input */}
        <div className="edgedeviceeditdialog-item-left">
          <div>
            <p className="edgedeviceeditdialog-input-description">
              Maximale Stromstärke (A)
            </p>
          </div>
        </div>
        <div className="edgedeviceeditdialog-item-right">
          <Input
            value={max_amp}
            onChange={(e) => setMax_amp(e.target.value)}
            placeholder="Maximale Stromstärke in Ampere"
            suffix="Ampere"
          />
        </div>
        <div className="edgedeviceeditdialog-item-left">
          <div>
            <p className="edgedeviceeditdialog-input-description">
            Netzanschluss Phase L1 
            </p>
          </div>
        </div>
        <div className="edgedeviceeditdialog-item-right">
          <Select 
            value={phaseL0}
            onChange={(e) => setPhaseL0(e)}
            placeholder="Phase L0 einstellen"
            style={{ width: "50%" }}
          >
            <Option value={1} key={1}>{1} </Option>
            <Option value={2} key={2}>{2} </Option>
            <Option value={3} key={3}>{3} </Option>
          </Select>
        </div>
        <div className="edgedeviceeditdialog-item-left">
          <div>
            <p className="edgedeviceeditdialog-input-description">
              Netzanschluss Phase L2
            </p>
          </div>
        </div>
        <div className="edgedeviceeditdialog-item-right">
          <Select 
            value={phaseL1}
            onChange={(e) => setPhaseL1(e)}
            placeholder="Phase L1 einstellen"
            style={{ width: "50%" }}
          >
            <Option value={1} key={1}>{1} </Option>
            <Option value={2} key={2}>{2} </Option>
            <Option value={3} key={3}>{3} </Option>
          </Select>
        </div>
        <div className="edgedeviceeditdialog-item-left">
          <div>
            <p className="edgedeviceeditdialog-input-description">
              Netzanschluss Phase L3
            </p>
          </div>
        </div>
        <div className="edgedeviceeditdialog-item-right">
          <Select 
            value={phaseL2}
            onChange={(e) => setPhaseL2(e)}
            placeholder="Phase L2 einstellen"
            style={{ width: "50%" }}
          >
            <Option value={1} key={1}>{1} </Option>
            <Option value={2} key={2}>{2} </Option>
            <Option value={3} key={3}>{3} </Option>
          </Select>
        </div>
      </div>
      
      </Spin>
      {/* Error text with dynamic render scss class */}
      <p
        className={
          error
            ? "edgedeviceeditdialog-error-text"
            : "edgedeviceeditdialog-error-text-hidden"
        }
      >
        {errorText}
      </p>
    </Modal>
  );
}
