/* -------------
      IMPORTS  
  -------------- */
// React import
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
// Component import
import App from "./app/App";
// Design import
import "./index.scss";
// Service import
import * as serviceWorker from "./serviceWorker";
import './fonts/Montserrat-Regular.ttf';
ReactDOM.render(
  
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin + "/dashboard"}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */},
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();