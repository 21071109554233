/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";

// Style import
import "../../design/components/whatsnewmodal.scss";

// Ant design import
import { Button, Checkbox, Modal } from "antd";

import { useAuth0 } from "@auth0/auth0-react";
/* --------------------
      WhatsNewModal
  ------------------- */
export default function WhatsNewModal(props) {
  //* -- VARIABLES -- *//

  //* -- STATES -- *//

  //* -- HANDLE METHODS -- *//
  // When "Schließen" button is clicked on edit modal, dependent from checkbox

  const { logout } =
    useAuth0();
  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={
        "Fehlende Berechtigung "
      }
      className="xxxx"
      visible={props.showNonElectricianModal}
      closable={false}
      footer={[
        <Button key="close" shape="round" type="primary" onClick={() => logout({ returnTo: window.location.origin })}>
          Zurück zum Login
        </Button>,
      ]}
    >
      <p className="whatsnewmodal-headline">Hallo,</p>
      <p>du hast dich mit einem Account angemeldet, der keine Berechtigung für das Elektriker-Dashboard hat.</p>
      <p>
        Um die Berechtigung für das Elektriker-Dashboard zu erlangen, kontaktiere bitte unseren Support:         
      </p>
      <p>
        support@chargeiq.de
      </p>
    </Modal>
  );
}
