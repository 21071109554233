/* -------------
      IMPORTS  
  -------------- */
// React import
// Ant design import
import create from "@ant-design/icons/lib/components/IconFont";
import { Button, Input, Modal, Select, Tooltip, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
// Style import
import "../../design/components/pooladddialog.scss";
// Services import
import { restservices } from "../../rest-services.js";
import LoadManagementModeModal from "../modals/LoadManagementModeModal";
import NewCategoryDialog from "../category/NewCategoryDialog";

/* --------------------
      POOLADDDIALOG
  ------------------- */
export default function PoolAddDialog(props) {
  //* -- VARIABLES -- *//
  const { Option } = Select;

  //* -- STATES -- *//
  const [pool_name, setPool_name] = useState("");
  const [load_management_mode, setLoad_management_mode] = useState();
  const [max_power, setMax_power] = useState();
  
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [groupId, setGroupId] = useState(""); 
  const [groupDropdownDisabled, setGroupDropdownDisabled] = useState(false); 
  const [createNewPoolCheckbox, setCreateNewPoolCheckbox] = useState(false); 

  const [selectedCategory, setSelectedCategory] = useState(""); 
  
  const [categoryDialogIsOpen, setCategoryDialogIsOpen] = useState(false);
  const [loadManagementModeModalOpen, setLoadManagementModeModalOpen] =
    useState(false);

  //* -- HANDLE METHODS -- *//
  // When "Abbrechen" button is clicked on edit modal
  const handleCancel = () => {
    props.setAddIsOpen(false);
  };

  const handleOpenLoadManagementModeModal = () => {
    setLoadManagementModeModalOpen(true);
  };

  const handleSetLoadManagementMode = (e) => {
    if (e === "DYNAMIC" || e === "DYNAMIC_WITH_BACKCOUPLING") {
      setLoadManagementModeModalOpen(true);
    }
    setLoad_management_mode(e);
  };

  const handleChangeGroup = (e) => {
    //console.log(e);
    setGroupId(e.value);
    setPool_name(e.label);
  }
  const handleChangeCreateNewPoolCheckbox = (e) => {
    //console.log(e); 
    setCreateNewPoolCheckbox(e.target.checked); 
    setGroupDropdownDisabled(e.target.checked); 
  }

  const handleOpenNewCategoryModal = () => {
    setCategoryDialogIsOpen(true);
  }

  useEffect( () => {
    //console.log(props.groupList); 

  }, [props.userList]);

  // When "Pool anlegen" button is clicked on edit modal
  const handleAddPool = async () => {
    // Check if all inputs are filled correctly
    if (
      //    /^\d+$/.test(maxLoad) &&
      load_management_mode !== undefined
    ) {
      setError(false);
      setErrorText("");

      let load_management_mode_text = "";
      if (load_management_mode === "NONE") {
        load_management_mode_text = "kein Lastmanagement";
      } else if (load_management_mode === "BASIC") {
        load_management_mode_text = "Basic";
      } else if (load_management_mode === "FIRST_COME_FIRST_SERVE") {
        load_management_mode_text = "First Come First Served";
      } else if (load_management_mode === "DOWNSCALING") {
        load_management_mode_text = "Downscaling";
      } else if (load_management_mode === "DOWNSCALING_WITH_BACKCOUPLING") {
        load_management_mode_text = "Downscaling m. Rückk.";
      } else if (load_management_mode === "DYNAMIC") {
        load_management_mode_text = "Dynamisch";
      } else if (load_management_mode === "DYNAMIC_WITH_BACKCOUPLING") {
        load_management_mode_text = "Dynamisch m. Rückk.";
      } else {
        load_management_mode_text = "Fehler #PT_001";
      }
      const newPool = {
        pool: {
          "cp_config": [],
          "max_power": max_power,
          "mode": load_management_mode,
          "category": selectedCategory
        },
        user_id: props.userId
      }
      const group =  {
        name: pool_name
      }
      let finalPoolName = ""; 
      if (createNewPoolCheckbox === true) {
        newPool.pool.group = group; 
        finalPoolName = pool_name; 
      }
      else {
        newPool.pool.group_id = groupId;
        finalPoolName = pool_name; 
      }
      //console.log(newPool); 
      await restservices
        .createNewPool(
          newPool
        )
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            props.setAddIsOpen(false);
            let lastKey = 0;
            var i;
            for (i = 0; i < props.poolList.length; i++) {
              lastKey = props.poolList[i].key;
            }
            //console.log(result.data.data.id); 
            if (createNewPoolCheckbox !== true){
              props.setPoolList((prevState) => [
                ...prevState,
                {
                  _id: result.data.data.id,
                  key: lastKey + 1,
                  pool_name: pool_name,
                  load_management_mode: load_management_mode,
                  load_management_mode_text: load_management_mode_text,
                  activeText: "inaktiv",
                  active: false, //by default inactive
                  max_amp: max_power,
                },
              ]);


              restservices.getPoolById(result.data.data.id)
              .then(data => {
                //console.log(data);     
                props.setEdgeList([]);
                const chargepoints = data.data.chargepoints; 
                //console.log(chargepoints); 
                
        
                if (chargepoints.length > 0){
                  let phaseRotation = [1,2,3];
                  let max_amp = ""; 
                  chargepoints.forEach((edgeDevice, i) => {
                    let phaseRotationText = "";
                    // get name  and evse id 
        
                    //console.log(edgeDevice); 
                        // get evse
                    const nextEdgeDevice = {
                      id: edgeDevice.identity,
                      friendly_name: edgeDevice.friendly_name,
                      evse_id: edgeDevice.evse_id,
                      max_amp: max_amp,
                      ev_status: edgeDevice.status,
                      phase_rotation: phaseRotation,              
                      phase_rotation_text: phaseRotationText,
                      key: i + 1,
                    };
                    props.setEdgeList((prevState) => [
                      ...prevState,
                      nextEdgeDevice,
                    ]);
                    const nextCpConfig = {
        
                      id: edgeDevice.identity,
                      evse_id: edgeDevice.evse_id,
                    }
        
                    props.setCpList((prevState) => [
                      ...prevState, 
                      nextCpConfig,
                    ]         
                    ); 
                    props.setPoolId(result.data.data.id);
        
                  });
                }
              }); 
            }
            else if (createNewPoolCheckbox === true) {
              const groupBody = {
                membership: {
                  group_id: result.data.data.group.id,
                  role: "electrician",
                  user_id: props.electricianId,
                }
              }
    
              restservices.assignToPool(groupBody).then((result)=> {
                //console.log(result); 
              })
            }
            //and then assign electrician to group
  
            
          }
          else{
            //console.log(result); 
            setError(true); 
            setErrorText("Fehler beim Anlegen. Für den Standort (Gruppe) wurde bereits ein Pool angelegt. ")
          }
          
        });
      //TODO Get IDs from result
    } else {
      // Check if "Poolname" input is filled correctly
      if ((pool_name === "" || pool_name === undefined) && (createNewPoolCheckbox === true)) {
        setError(true);
        setErrorText("Bitte gültigen Poolnamen angeben");
      }
      else if (max_power === "" || max_power === undefined) {
        setError(true);
        setErrorText("Bitte gültige max. Anschlussleistung angeben");
      }
      // Check if "Betriebsmodus" input is filled correctly
      else if (load_management_mode === undefined) {
        setError(true);
        setErrorText("Bitte einen Betriebsmodus angeben");
        ////console.log("load_management_mode fehlerhaft");
      } else console.log("Fehler #PAD_001");
    }
  };

  //* --  MAIN RETURN  -- *//
  return (
    <Modal
      title={"Neuen Pool hinzufügen zur Kategorie " + props.category}
      visible={props.addIsOpen}
      onCancel={handleCancel}
      width="800px"
      closable={false}
      footer={[
        <Button key="back" shape="round" onClick={handleCancel}>
          Abbrechen
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          onClick={handleAddPool}
        >
          Pool anlegen
        </Button>,
      ]}
    >
    <NewCategoryDialog
      isOpen={categoryDialogIsOpen}
      setIsOpen ={setCategoryDialogIsOpen}
      category= {selectedCategory}
      setCategory={setSelectedCategory}
      setCategoryList={props.setCategoryList}
    />
      {/* Edit Pool Modal */}
      <LoadManagementModeModal
        isOpen={loadManagementModeModalOpen}
        setIsOpen={setLoadManagementModeModalOpen}
      />

      <div className="pooladddialog-container">
        {/* "Poolname" Input */}
      
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">Gruppen-Name </p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Input
            value={pool_name}
            disabled={!groupDropdownDisabled} 
            onChange={(e) => setPool_name(e.target.value)}
            placeholder="Poolnamen eingeben"
          />
        </div>
          
        {/* "User Email" Input */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">Gruppe auswählen</p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Select
            labelInValue
            disabled={groupDropdownDisabled} 
            onChange={(e) => handleChangeGroup(e)}
            placeholder="Gruppe auswählen"
            style={{ width: "100%" }}
          >
            {props.groupList.map((item, index) => (
              <Option value={item.group.id} key={index}>
                {item.group.name}
              </Option>
            ))}
          </Select>
          <Checkbox 
            onChange={(e)=>handleChangeCreateNewPoolCheckbox(e)}>
            Pool mit neuer Gruppe erstellen
          </Checkbox>
        </div>

        {/* "max. Anschlussleistung" Input */}
        <div className="pooladddialog-item-left">
          <div>
            <p className="pooladddialog-input-description">
              Max. Anschlussleistung
            </p>
          </div>
        </div>
        <div className="pooladddialog-item-right">
          <Input
            value={max_power}
            onChange={(e) => setMax_power(e.target.value)}
            suffix="Kilowatt"
            placeholder="Max. Anschlussleistung eingeben"
          />
        </div>
        {/* "Betriebsmodus" Input */}
        <div className="pooladddialog-item-left">
          <p className="pooladddialog-input-description">Betriebsmodus</p>
        </div>
        <div className="pooladddialog-item-right">
          <Select
            value={load_management_mode}
            onChange={(e) => handleSetLoadManagementMode(e)}
            placeholder="Betriebsmodus auswählen"
            style={{ width: "100%" }}
          >
            <Option value="NONE" className="pooladddialog-select-option">
              kein Lastmanagement
            </Option>
            <Option value="BASIC" className="pooladddialog-select-option">
              Basic
            </Option>

            <Option value="DOWNSCALING" className="pooladddialog-select-option">
              Downscaling
            </Option>
            <Option
              value="DOWNSCALING_WITH_BACKCOUPLING"
              className="pooladddialog-select-option"
            >
              Downscaling m. Rückk.
            </Option>
            <Option value="DYNAMIC" className="pooladddialog-select-option">
              Dynamisch
            </Option>
            <Option
              value="DYNAMIC_WITH_BACKCOUPLING"
              className="pooladddialog-select-option"
            >
              Dynamisch m. Rückk.
            </Option>
          </Select>
          <Tooltip title={"Was bedeutet das?"}>
            <Button shape="round" onClick={handleOpenLoadManagementModeModal}>
              ?
            </Button>
          </Tooltip>
        </div>
        {/* "Betriebsmodus" Input */}
        <div className="pooladddialog-item-left">
          <p className="pooladddialog-input-description">Kategorie</p>
        </div>
        <div className="pooladddialog-item-right">
          

        <Select
          showSearch
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e)}
          placeholder="Kategorie eingeben"
          filterOption={(input, option) =>
            (option.props.children).toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}           
        >
          {      
            props.categoryList.map((item, index) => (
              <Option value={item} key={index}>{item}</Option>
            ))
          }     
        </Select>
        
        <Tooltip title={"Neue Kategorie anlegen"}>
            <Button shape="round" onClick={handleOpenNewCategoryModal}>
              +
            </Button>
          </Tooltip>
        {/*}
        <Input
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            placeholder="Kategorie eingeben"
          />
        {*/}
        </div>
      </div>
      {/* Error text with dynamic render scss class */}
      <p
        className={
          error ? "pooladddialog-error-text" : "pooladddialog-error-text-hidden"
        }
      >
        {errorText}
      </p>
    </Modal>
  );
}
