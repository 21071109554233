import axios from "axios";
import Cookies from "js-cookie";

export const restservices = {
  auth0Url: process.env.REACT_APP_AUTH0_URL,
  //for testing 
  centralServiceUrl: process.env.REACT_APP_CENTRIQ_URL,
  //delete after test is successful 
  // User operations */

  getUserWithExtId: function (extId){
    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      // Reset to Auth0 user 
      axios.get(this.centralServiceUrl + "/api/core/user/"+encodeURIComponent(extId)+"?by=ext_id", { headers }).then(
        (response) => {
          ////console.log(response); 
          if (response.data !== undefined){
            res(response.data);
          }
          else {
            res("ERR_USER_NONEXISTENT")
          }
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  getUserWithUserId: function (userId){
    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      // Reset to Auth0 user 
      axios.get(this.auth0Url + "/users?user_ids=[\"" + userId + "\"]", { headers }).then(
        (response) => {
          if (response.data[0] !== undefined){
            res(response.data[0].email);
          }
          else {
            res("ERR_USER_NONEXISTENT")
          }
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  getUserWithEmailCentral: function (email){
    
    //console.log(this.centralServiceUrl);
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      // Reset to Auth0 user 
      const parsedEmail = email.replace("@", "%40")
      axios.get(this.centralServiceUrl + "/api/core/user/"+parsedEmail+"?by=email", { headers }).then(
        (response) => {
          if (response.data !== undefined){
            res(response.data);
          }
          else {
            res("ERR_USER_NONEXISTENT")
          }
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  getChargepointsWithUserCentral: function (user_id){
    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      // Reset to Auth0 user 
      axios.get(this.centralServiceUrl + "/api/core/user/"+user_id+"/chargepoint", { headers }).then(
        (response) => {
          if (response.data !== undefined){
            res(response.data);
          }
          else {
            res("ERR_USER_NONEXISTENT")
          }
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  getUsersWithUserIds: function (userIds){
    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      // Reset to Auth0 user 
      axios.get(this.auth0Url + "/users?user_ids=" + JSON.stringify(userIds), { headers }).then(
        (response) => {
          if (response.data !== undefined){
            const users = response.data.map((user) => {
              return ({
                user_id: user.user_id,
                email: user.email
              })

            })
            res(users);
          }
          else {
            res("ERR_USER_NONEXISTENT")
          }
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  

  getUserWithCentralUserId: function (userId) {
    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.get(
          this.centralServiceUrl + "/api/core/user/" + userId
        , {headers})
        .then(
          (response) => {
            res(response);
          },
          (error) => {
            err(error);
          }
        );
    });
  },


  //Assign electrician to pool 
  assignToPool:  function (body) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };

    const group_id = body.membership.group_id; 
    return new Promise((res, err) => {
      axios.put(this.centralServiceUrl + "/api/core/group/" + group_id + "/membership", body, { headers }).then(
        (response) => {
          res(response);
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  getGroup: function (groupId) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.get(this.centralServiceUrl + "/api/core/group/" + groupId , { headers}).then(
        (response) => {
          //console.log(response);
          res(response.data);
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  updateGroup: function (groupId, body) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.put(this.centralServiceUrl + "/api/core/group/" + groupId ,body,  { headers}).then(
        (response) => {
          //console.log(response);
          res(response.data);
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  //Create new pool
  //Already reset to new central service

  getAllPools: function () {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.get(this.centralServiceUrl + "/api/electric/pool" , { headers}).then(
        (response) => {
          //console.log(response);
          res(response.data);
        },
        (error) => {
          err(error);
        }
      );
    });
  },
  //Get all pools from electrican
  //Already reset to new central service
  getAllPoolsFromElectrician: function (electricianId) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    //console.log(this.centralServiceUrl); 
    return new Promise((res, err) => {
      axios.get(this.centralServiceUrl + "/api/electric/user/" + electricianId + "/pool" , { headers}).then(
        (response) => {
          //console.log(response);
          res(response.data);
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  
  //Get all pools from electrican
  //Already reset to new central service
  getPoolById: function (poolId) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.get(this.centralServiceUrl + "/api/electric/pool/" + poolId, { headers}).then(
        (response) => {
          //console.log(response);
          res(response.data);
        },
        (error) => {
          err(error);
        }
      );
    });
  },

  createNewPool:  function (body) {    
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    //console.log(body); 
    return new Promise((res, err) => {
      axios.post(this.centralServiceUrl + "/api/electric/pool", body, { headers }).then(
        (response) => {
          res(response);
        }
      ).catch(err => {
        //console.log(err); 
        res(err.message); 
      });
    });
  },
  //TODO: S
  updatePool: function (body, poolId) {     
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.put(this.centralServiceUrl + "/api/electric/pool/" + poolId, body , { headers }).then(
        (response) => {
          res(response);
        },
        (error) => {
          if (error.response.status === 401) {
            //console.log(error.response); 
            res(error.response); 
          }
          else{
            //console.log(error); 
            err(error);
          }
        }
      );
    });
  },

  //TODO: S
  deletePool: function (poolId) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "Accept": "application/json",
      "Authorization": "Bearer "+ accessToken
    };

    return new Promise((res, err) => {
      axios.delete(this.centralServiceUrl + "/api/electric/pool/" + poolId,{ headers }).then(
        (response) => {
          res(response);
        },
        (error) => {
          err(error);
        }
      );
   
    });
  },
  createChargeStation: function (body) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.post(this.centralServiceUrl + "/api/core/station" , body, {headers}).then(
        (response) => {
          res(response);
        },
        (error) => {
          err(error);
        }
      );
    });
  },


  deleteChargeStation: function (identity) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    return new Promise((res, err) => {
      axios.post(this.centralServiceUrl + "/api/core/station/" + identity, {headers}).then(
        (response) => {
          res(response);
        },
        (error) => {
          err(error);
        }
      );
    });
  },


  restartEdgeDevice: function (identity) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };

    //console.log(accessToken); 
    const body = {
      reset: "Hard"
    }; 
    return new Promise((resolve, reject) => {

        axios.put(this.centralServiceUrl + "/api/core/station/" + identity , body, { headers }).then(result => {
          //console.log(result); 
            resolve(result.data)
        }, error => {
            reject(error.data);
        })
    }) 
},


  //TODO: Not necessary anymore since amps are saved in cp_config
  /*
  setAmp: function (identity, amp) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };

    const body = {
      "params": {
        "amps": amp,
        "stack_level": 1
      }
    }
    return new Promise((res, err) => {
      axios.put(this.centralServiceUrl + "/api/command/simple_amps/" + identity + "/1", body, { headers }).then(
        (response) => {
          res(response);
        },
        (error) => {
          err(error);
        }
      );
    });
  },
   */
  
  createUser: function (body){
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    };
    //console.log(accessToken); 

    return new Promise((res, err) => {
      axios
        .post(
          this.centralServiceUrl + "/api/core/user" , body
        , { headers })
        .then(
          (response) => {
            res(response);
          },
          (error) => {
            
            err(error);
          }
        );
    });
  },
  /*
  getSummary: function () {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "content-type": "application/json",
      "Authorization": "Bearer "+ accessToken
    }; 
    return new Promise((res, err) => {
      axios
        .get(
          this.lmElectricianUrl + "/get-lm-summary-by-electrician" 
        , { headers })
        .then(
          (response) => {
            //console.log(response); 
            res(response);
          },
          (error) => {
            err(error);
          }
        );
    });
  },

  
  getDetailedReport: function (startTime, endTime, type) {
    const accessToken = Cookies.get("access_token"); 
    const headers = {
      "responseType": "blob",
      "Authorization": "Bearer "+ accessToken
    }; 
    return new Promise((res, err) => {
      axios
        .get(
          this.lmElectricianUrl + "/get-chargingstation-report-by-electrician/" + startTime + "/" + endTime + "/" + type
        , { headers })
        .then(
          (response) => {
            //console.log(response); 
            res(response);
          },
          (error) => {
            err(error);
          }
        );
    });
  },
*/
};

